export const AccountTypeExclusionEditableTC = [
  { id: "outstanding_exclusion", type: "text" },
  { id: "os_exclusion_limit", type: "number" },
  { id: "od_exclusion", type: "text" },
  { id: "od_exclusion_limit", type: "number" },
  { id: "write_off_exclusion", type: "text" },
  { id: "write_off_exclusion_limit", type: "number" },
  {
    id: "fresh_disbursements_exclusions",
    type: "select",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
  },
  {
    id: "classification",
    type: "select",
    options: [
      {
        label: "MFI",
        value: "MFI",
      },
      {
        label: "Long-term Secured",
        value: "Long-term Secured",
      },
      {
        label: "Secured",
        value: "Secured",
      },
      {
        label: "Unsecured",
        value: "Unsecured",
      },
    ],
  },
];

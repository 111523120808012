import React, { useState } from "react";
import { filter } from "../images";

const SearchFilter = ({ placeholder, globalFilter, setGlobalFilter }) => {
  const [search, setSearch] = useState(globalFilter);

  function onChange(value) {
    setSearch(value);
    setGlobalFilter(value);
  }
  return (
    <div className="px-0 d-flex align-items-center gap-3">
      <img
        src={filter}
        style={{ height: "38px", fill: "#64748b" }}
        alt={"filter"}
      ></img>
      <input
        className="p-2"
        style={{ fontSize: "15px", width: "auto" }}
        value={search}
        placeholder={placeholder}
        onChange={(e) => {
          let value = e.target.value;
          onChange(value);
        }}
      ></input>
    </div>
  );
};

export default SearchFilter;

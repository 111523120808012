export const AccountTypeExclusionTableColumns = [
  {
    Header: "Account Type Exclusion",
    columns: [
      {
        Header: "Account Type",
        accessor: "account_type",
      },
      {
        Header: "Outstanding Exclusion",
        accessor: "outstanding_exclusion",
      },
      {
        Header: "OS Exclusion - Limit",
        accessor: "os_exclusion_limit",
      },
      {
        Header: "OD Exclusion",
        accessor: "od_exclusion",
      },
      {
        Header: "OD Exclusion - Limit",
        accessor: "od_exclusion_limit",
      },
      {
        Header: "Write-off Exclusion",
        accessor: "write_off_exclusion",
      },
      {
        Header: "Write-off Exclusion - Limit",
        accessor: "write_off_exclusion_limit",
      },
      {
        Header: "Fresh Disbursements Exclusions",
        accessor: "fresh_disbursements_exclusions",
      },
      {
        Header: "Classification",
        accessor: "classification",
      },
      { Header: "Max Loan Amount #1", accessor: "max_loan_amount_1" },
      { Header: "Max Loan Amount #2", accessor: "max_loan_amount_2" },
      { Header: "Max Loan Amount #3", accessor: "max_loan_amount_3" },
      { Header: "Interest Rate %", accessor: "interest_rate_field" },
      {
        Header: "Calculated EMI Applicability",
        accessor: "calculated_emi_applicability",
      },
      { Header: "Tenure Category", accessor: "tenure_category" },
      { Header: "Total Outstanding", accessor: "total_outstanding" },
      {
        Header: "Average Outstanding per Live Loan",
        accessor: "average_outstanding_per_live_loan",
      },
      { Header: "No. of Live Loans", accessor: "no_of_live_loans" },
    ],
  },
];

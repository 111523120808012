import { NewClientTableColumns } from "./TableColumns";
import { NewClientEditableTC } from "./EditableTC";
import { NewClientFilters } from "./Filters";
import { NewClientSelectableTC } from "./SelectableTC";
import { NewClientStaticData } from "./StaticData";
import { NewClientStyles } from "./Styles";

export {
  NewClientTableColumns,
  NewClientEditableTC,
  NewClientFilters,
  NewClientSelectableTC,
  NewClientStaticData,
  NewClientStyles,
};

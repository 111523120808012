import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";

const Page = ({ isAdmin, children, user, updateUser, userAccess }) => {
  return (
    <div className="h-100vh overflow-hidden d-flex flex-column" style={{ height: "100vh" }}>
      <Header
        user={user}
        updateUser={updateUser}
        userAccess={userAccess}
      ></Header>
      <Outlet></Outlet>
    </div>
  );
};

export default Page;

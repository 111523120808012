export const NewClientStyles = (column) => {
  return {
    textAlign: [
      "Conditional Approval",
      "Specific Exclusion 1 (Full)",
      "#1",
      "#2",
      "#1A",
      "#2A",
      "#3A",
      "Specific Exclusion #2 (Full)",
      "Specific Exclusion #2 (Selective 1 and 2)",
      "Specific Exclusion #2 (Selective 3 and 4)",
      "Specific Exclusion #2 (Selective 5 and 6)",
      "Specific Exclusion #2 (Selective 7)",
      "Selective Category #1",
      "Selective Category #2",
      "Selective Category #3",
      "Selective Category #4",
      "Selective Category #5",
      "Selective Category #6",
      "Selective Category #7",
    ].includes(column.Header)
      ? "center"
      : "",
    maxWidth: column.Header === "-" ? "120px" : "",
    minWidth: column.Header === "-" ? "30px" : "145px",
    width: column.Header === "Manually Entered" ? "80px" : "auto",
    borderWidth: ["Approve", "Refer", "Reject", "Assumptions"].includes(
      column.Header
    )
      ? "1px 1px 0px 1px"
      : ["Approve 2", "Refer 2", "Reject 2", "Assumptions 2"].includes(
          column.Header
        )
      ? "0px 1px 1px 1px"
      : "1px",
    backgroundColor: ["Rules", "Rules 2"].includes(column.Header)
      ? "black"
      : ["Approve", "Approve 2"].includes(column.Header)
      ? "green"
      : column.Header === "Conditional Approval"
      ? "#0070c0"
      : [
          "Approval Limit",
          "Condition 1",
          "Value 1",
          "Condition 2",
          "Value 2",
          "Parameter ",
        ].includes(column.Header)
      ? "#c6d9f0"
      : ["Refer", "Refer 2"].includes(column.Header)
      ? "#e36c09"
      : ["Reject", "Reject 2"].includes(column.Header)
      ? "red"
      : ["Assumptions", "Assumptions 2"].includes(column.Header)
      ? "#ffc000"
      : ["Specific Exclusion 1 (Full)"].includes(column.Header)
      ? "#494429"
      : ["#1", "#2"].includes(column.Header)
      ? "#c4bd97"
      : ["#1A", "#2A", "#3A"].includes(column.Header)
      ? "#595959"
      : [
          "Specific Exclusion #2 (Full)",
          "Specific Exclusion #2 (Selective 1 and 2)",
          "Specific Exclusion #2 (Selective 3 and 4)",
          "Specific Exclusion #2 (Selective 5 and 6)",
          "Specific Exclusion #2 (Selective 7)",
        ].includes(column.Header)
      ? "#262626"
      : [
          "Selective Category #1",
          "Selective Category #2",
          "Selective Category #3",
          "Selective Category #4",
          "Selective Category #5",
          "Selective Category #6",
          "Selective Category #7",
        ].includes(column.Header)
      ? "#7f7f7f"
      : "#f2f2f2",
    color: [
      "Conditional Approval",
      "Approve",
      "Rules",
      "Refer",
      "Reject",
      "Specific Exclusion 1 (Full)",
      "#1A",
      "#2A",
      "#3A",
      "Specific Exclusion #2 (Full)",
      "Specific Exclusion #2 (Selective 1 and 2)",
      "Specific Exclusion #2 (Selective 3 and 4)",
      "Specific Exclusion #2 (Selective 5 and 6)",
      "Specific Exclusion #2 (Selective 7)",
      "Selective Category #1",
      "Selective Category #2",
      "Selective Category #3",
      "Selective Category #4",
      "Selective Category #5",
      "Selective Category #6",
      "Selective Category #7",
    ].includes(column.Header)
      ? "white"
      : ["Rules 2"].includes(column.Header)
      ? "black"
      : ["Approve 2"].includes(column.Header)
      ? "green"
      : ["Refer 2"].includes(column.Header)
      ? "#e36c09"
      : ["Reject 2"].includes(column.Header)
      ? "red"
      : ["Assumptions 2"].includes(column.Header)
      ? "#ffc000"
      : "",
  };
};

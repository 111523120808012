import React, { useState } from "react";
import { logo, signout, usericon, xmark } from "../images";
import Modal from "./modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal as BModal } from "bootstrap";
const NavLink = ({ to, children, lastSegment }) => {
  let isActive;

  if (lastSegment === "app" && to === "") {
    isActive = true;
  } else {
    isActive = lastSegment === to;
  }
  return (
    <Link
      to={to}
      className={"navlink text-decoration-none p-3 fw-semibold"}
      style={{
        color: isActive ? "black" : "#868b91",
        fontSize: "17px",
        cursor: "pointer",
      }}
    >
      {" "}
      {children}
    </Link>
  );
};

const Header = ({ user, updateUser, userAccess }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const lastSegment = currentPath.substring(currentPath.lastIndexOf("/") + 1);

  let routes = currentPath.split("/");

  function openModal() {
    let elem = document.getElementById("user-dialog-box");
    BModal.getOrCreateInstance(elem).show();
  }
  function closeModal() {
    let elem = document.getElementById("user-dialog-box");
    BModal.getOrCreateInstance(elem).hide();
  }

  return (
    <div
      id="header"
      className="px-4 py-2 text-decoration-none d-flex align-items-center"
      // style={{ backgroundColor: "#122248", height: "60px" }}
      style={{
        height: "60px",
        border: "1px solid #dee2e6",
        borderWidth: "0px 0px 1px 0px",
        backgroundColor: "#fff",
      }}
    >
      <img
        src={logo}
        style={{ width: "80px", height: "auto" }}
        alt={"logo"}
      ></img>
      {routes.includes("login") || routes.includes("reset") ? (
        <></>
      ) : (
        <div
          className="d-flex ms-auto align-items-center "
          style={{ color: "#868b91" }}
        >
          {userAccess === "Admin Access" && (
            <>
              <NavLink to={"admin"} lastSegment={lastSegment}>
                Admin
              </NavLink>
              <NavLink to={"xml"} lastSegment={lastSegment}>
                Engine
              </NavLink>
            </>
          )}
          <NavLink to={""} lastSegment={lastSegment}>
            Results
          </NavLink>
          {userAccess === "Admin Access" && (
            <>
              <NavLink to={"accountTypeExclusion"} lastSegment={lastSegment}>
                Account Type Exclusion
              </NavLink>
              <NavLink to={"loanPack"} lastSegment={lastSegment}>
                Loan Pack
              </NavLink>
              <NavLink to={"newClient"} lastSegment={lastSegment}>
                New Client
              </NavLink>
              <NavLink to={"repeatClient"} lastSegment={lastSegment}>
                Repeat Client
              </NavLink>
              <NavLink to={"employee"} lastSegment={lastSegment}>
                EMP CB
              </NavLink>
            </>
          )}

          <div className="user-dialog-box-wrapper">
            <div
              className="p-1 "
              style={{
                borderRadius: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                openModal();
              }}
            >
              <img
                src={usericon}
                style={{
                  width: "26px",
                  height: "22px",
                  borderRadius: "100%",
                }}
              />
            </div>
          </div>
          <div id="">
            <Modal
              modalId="user-dialog-box"
              size={"sm"}
              body={
                <>
                  <div
                    className="p-3"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      backgroundColor: "rgb(245 249 255)",
                    }}
                  >
                    <div className="d-flex align-items-center py-2 px-4">
                      <img
                        src={usericon}
                        style={{
                          height: "20px",
                          borderRadius: "100%",
                          marginRight: "30px",
                        }}
                      />
                      <div className="" style={{ fontSize: "16px" }}>
                        {user && user["employee_name"]
                          ? user["employee_name"]
                          : "N/A"}{" "}
                        <br />
                        <span style={{ fontSize: "14px", color: "#444746" }}>
                          ({userAccess ? userAccess : "N/A"})
                        </span>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center py-2 px-4 logout-hover "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.setItem("userId", "");
                        localStorage.setItem("accessToken", "");
                        updateUser(null);
                        closeModal();
                        navigate("/login");
                      }}
                    >
                      <img
                        src={signout}
                        style={{
                          height: "20px",
                          marginRight: "30px",
                        }}
                      ></img>
                      <div style={{ fontWeight: "600", fontSize: "16px" }}>
                        Logout
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

export const LoanPackTableColumns=[
  {
    Header: "Equifax Result",
    columns: [
      {
        Header: "Sr No.",
        accessor: "serial_number",
      },
      {
        Header: "Loan Amount",
        accessor: "loan_amount",
      },
      {
        Header: "Tenure",
        accessor: "tenure",
      },
      {
        Header: "Installment Amount",
        accessor: "installment_amount",
      },
      {
        Header: "Maximum Loan Cycle",
        accessor: "maximum_loan_cycle",
      },
    ],
  },
];

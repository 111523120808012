import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../components/api";
import {
  getEngineOutput,
  postEngineOutput,
  recalculateCbEngine,
  testAPI,
  updateEngineOutput,
} from "../urls";
import BasicTable from "../components/BasicTable2";
import SelectInput from "../components/SelectInput";

const DataBox = ({
  data,
  tableData,
  setTableData,
  setIsRecalculate,
  userAccess,
  loanPackData,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Wait for userAccess to be available before rendering the component
    if (userAccess !== null) {
      setIsLoading(false);
    }
  }, [userAccess]);

  function roundOffIfFloat(string) {
    const floatRegex = /^[+-]?\d+(\.\d+)?$/;
    const intRegex = /^[+-]?\d+$/;

    if (intRegex.test(string)) {
      return string;
    } else if (floatRegex.test(string)) {
      const number = parseFloat(string);
      return Math.round((number + Number.EPSILON) * 100) / 100;
    } else {
      return string;
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="container-fluid m-0 h-100">
      <div
        className="row h-100"
        style={{
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          fontWeight: "700",
          fontStyle: "normal",
          lineHeight: "24px",

          verticalAlign: "middle",
          textAlign: "left",
          boxShadow: "rgba(21, 92, 171, 0.16) 0px 1px 4px",
          backgroundColor: "#FFF",
          padding: "32px",
        }}
      >
        {data[0].Heading && (
          <div
            className="section_heading py-2 mb-4"
            style={{
              color: "black",
            }}
          >
            {data[0].Heading}
          </div>
        )}
        {data[0].cells.map((cell, key) => {
          let loan_amount_override_options;
          let tenure_override_manual_field_options = [];

          if (
            cell.accessor === "select" &&
            cell.key === "loan_amount_override"
          ) {
            loan_amount_override_options = loanPackData.map((entry) => {
              return entry.loan_amount;
            });
            loan_amount_override_options.push(0);
            loan_amount_override_options = [
              ...new Set(loan_amount_override_options),
            ];
            loan_amount_override_options.sort((a, b) => a - b);
            loan_amount_override_options = loan_amount_override_options.map(
              (option) => {
                if (option === 0) {
                  return {
                    label: "Null",
                    value: option,
                  };
                }
                return {
                  label: option,
                  value: option,
                };
              }
            );
          }
          if (
            cell.accessor === "select" &&
            cell.key === "tenure_override_manual_field" &&
            tableData
          ) {
            tenure_override_manual_field_options.push(0);
            if (tableData && tableData["loan_amount_override"] == 0) {
              tenure_override_manual_field_options.push(0);
            } else {
              loanPackData.forEach((entry) => {
                if (entry.loan_amount == tableData["loan_amount_override"]) {
                  tenure_override_manual_field_options.push(entry.tenure);
                }
              });
            }

            tenure_override_manual_field_options = [
              ...new Set(tenure_override_manual_field_options),
            ];
            tenure_override_manual_field_options =
              tenure_override_manual_field_options.map((option) => {
                if (option === 0) {
                  return {
                    label: "Null",
                    value: option,
                  };
                }
                return {
                  label: option,
                  value: option,
                };
              });
          }
          let options = {
            loan_amount_override: loan_amount_override_options,
            tenure_override_manual_field: tenure_override_manual_field_options,
          };
          return (
            <div
              key={key}
              className={
                data[0].cells.length > 3 ? "col-4 my-2 mb-3" : " col my-2"
              }
            >
              <div className="data_heading mb-1" style={{}}>
                {cell.Header}
              </div>
              <div className="data_content" style={{}}>
                {cell.accessor !== null &&
                cell.accessor !== NaN &&
                cell.accessor !== "" &&
                typeof cell.accessor !== "undefined" ? (
                  cell.accessor === "select" ? (
                    <>
                      <SelectInput
                        label={cell.Header}
                        disabled={
                          userAccess === "Write Access" ||
                          userAccess === "Admin Access"
                            ? false
                            : true
                        }
                        wrapperClassName={"h-100"}
                        className={"w-50 h-100 py-1 border border-dark rounded"}
                        value={tableData && tableData[cell.key]}
                        onChange={(e) => {
                          setIsRecalculate(true);
                          setTableData((prevTableData) => {
                            const updatedTableData = {
                              ...prevTableData,
                              [cell.key]: e.target.value,
                            };
                            return updatedTableData; // Return the updated state
                          });
                        }}
                        options={options[cell.key]}
                      />
                    </>
                  ) : (
                    roundOffIfFloat(cell.accessor)
                  )
                ) : (
                  "N/A"
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CBOutputPage = ({ userAccess }) => {
  const [tableData, setTableData] = useState([]); //memoised this
  const [loanData, setLoanData] = useState([]); //memoised this
  const [loanPackData, setLoanPackData] = useState([]);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [filterHeight, setFilterHeight] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [attachmentFileType, setAttachmentFileType] = useState("");
  const [attachmentFile, setAttachmentFile] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [isRecalculate, setIsRecalculate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const referenceID = location.state.reference;
  const creation_date = location.state.creation_date;

  useEffect(() => {
    getData();
    setHeaderHeight(document.querySelector("#header").offsetHeight);
    setFilterHeight(document.querySelector("#filter").offsetHeight);
    if (userAccess !== null) {
      setIsLoading(false);
    }
  }, [referenceID]);

  function getData() {
    API({
      ...getEngineOutput,
      params: {
        reference_id: referenceID,
        creation_date: creation_date,
      },
      onSuccess: (res) => {
        let data = res.data.data;
        setTableData(data.engine_output_data);
        setLoanData(data.loan_data);
        setComments(data.comments_data);
        setAttachments(data.attachments_data);
        setLoanPackData(data.loan_pack_data[0]);
      },
      onError: (error) => {
        // alert(error);
        console.log(error, "error");
      },
    });
  }

  function postData(request_type) {
    let formData = new FormData();
    formData.append(`request_type`, request_type);
    formData.append(`reference_id`, referenceID);
    formData.append(`creation_date`, creation_date);

    if (request_type === "comment") {
      formData.append(`comment`, comment);
    }
    if (request_type === "attachment") {
      formData.append(`attachment_file`, attachmentFile[0]);
      formData.append(`attachment_file_type`, attachmentFileType);
    }
    API({
      ...postEngineOutput,
      body: formData,
      isFormData: true,
      onSuccess: (res) => {
        window.location.reload(false);
      },
      onError: (error) => {
        alert(error);
      },
    });
  }

  function updateData() {
    let url, body;
    if (isRecalculate) {
      url = recalculateCbEngine;
      body = {
        creation_date: creation_date,
        reference_id: referenceID,
        tenure_override_manual_field: tableData["tenure_override_manual_field"],
        loan_amount_override: tableData["loan_amount_override"],
      };
    } else {
      if (tableData["recommendation_override"]) {
        url = updateEngineOutput;
        body = {
          reference_id: referenceID,
          creation_date: creation_date,
          recommendation_override: tableData["recommendation_override"],
        };
      } else {
        return 0;
      }
    }
    API({
      ...url,
      body: body,
      onSuccess: (res) => {
        window.location.reload(false);
      },
      onError: (error) => {
        alert(error);
      },
    });
  }

  function testAPIFunc(result) {
    let body = {
      date_of_issue: tableData["date_of_issue"],
      member_id: tableData["member_id"],
      reference_number: tableData["reference"],
      group_name: tableData["group_name"],
      name: tableData["name"],
      spouse_name: tableData["spouse_name"],
      decision: result,
      approved_loan_amount: tableData["final_loan_amount"],
      approved_tenure: "approved_tenure",
      installment_amount: tableData["installment_amount"],
      total_calculated_emi:
        tableData["self_calculated_emi"] + tableData["spouse_calculated_emi"],
    };

    API({
      ...testAPI,
      body: body,
      onSuccess: (res) => {
        // window.location.reload(false);
      },
      onError: (error) => {
        alert(error);
      },
    });
  }

  const commentsColumns = useMemo(
    () => [
      {
        Header: "Comment",
        accessor: "comment",
      },
      {
        Header: "Commented By",
        accessor: "commented_by",
      },
      {
        Header: "Date and Time",
        accessor: "date_and_time",
        Cell: ({ row }) => {
          // console.log(row, "hahaha");
          if (row.values.date_and_time) {
            // console.log(row.values.date_and_time);
            return <>{row.values.date_and_time.split("T")[0]}</>;
          }
        },
      },
    ],

    []
  );

  const commentsMemoTableData = useMemo(
    () =>
      comments && comments.length !== 0
        ? comments
        : [{ comment: "No Comments" }],
    [comments]
  );
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  return (
    <div
      style={{
        backgroundColor: "#f5f8fd",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      }}
    >
      <div
        id="filter"
        className="py-4 px-4 "
        style={{
          position: "sticky",
          top: "0",
          boxShadow: "2px 3px 5px rgba(21, 92, 171, 0.1)",
          zIndex: "1",
          backgroundColor: "#FFF",
        }}
      >
        <div className="m-auto w-100 d-flex  align-items-center">
          <button
            disabled={isDisabled}
            className="px-3 py-2 fw-semibold me-3"
            style={{
              backgroundColor: isDisabled ? "#64748b" : "#000000",
              color: "white",
              borderRadius: "4px",
              transition: "0.3s",
              cursor: "pointer",
              fontSize: "12px",
              // marginRight: "",
            }}
            onClick={() => navigate(-1)}
          >
            {"< Back"}
          </button>
          <div className="" style={{ fontSize: "16px", fontWeight: "bold" }}>
            <span>Loan Application : </span>
            <span style={{ fontWeight: "500" }}>{tableData["reference"]}</span>
            <span className="ms-5"> Creation Date : </span>
            <span style={{ fontWeight: "500" }}>
              {tableData["creation_date"]}
            </span>
          </div>
          <div className="ms-auto">
            {" "}
            <div
              className="d-flex align-items-center gap-3 py-2"
              style={{ fontSize: "12px" }}
            >
              <button
                disabled={isDisabled}
                className="px-3 py-2 fw-semibold"
                style={{
                  backgroundColor: isDisabled ? "#64748b" : "#000000",
                  color: "white",
                  borderRadius: "4px",
                  transition: "0.3s",
                  cursor: "pointer",
                }}
                onClick={updateData}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2" style={{ overflowY: "overlay" }}>
        <div
          id={""}
          className="m-auto w-100 container-fluid"
          style={{
            height: `calc(100vh - ${headerHeight}px - ${filterHeight}px)`,
            fontSize: "10px",
          }}
        >
          <div className="row">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-between my-4">
              <DataBox
                data={useMemo(
                  () => [
                    {
                      Heading: "CLIENT DETAILS",
                      // heading_bgcolor: "#31859b",
                      cells: [
                        {
                          Header: "Date of Issue",
                          accessor: tableData["date_of_issue"],
                        },
                        { Header: "BRANCH ID", accessor: tableData["branch"] },
                        {
                          Header: "BRANCH NAME",
                          accessor: tableData["branch"],
                        },
                        {
                          Header: "GROUP NAME",
                          accessor: tableData["group_name"],
                        },
                        {
                          Header: "MEMBER ID",
                          accessor: tableData["member_id"],
                        },
                        { Header: "NAME", accessor: tableData["name"] },
                        {
                          Header: "SPOUSE NAME",
                          accessor: tableData["spouse_name"],
                        },
                        {
                          Header: "CLIENT CATEGORY",
                          accessor: tableData["client_category"],
                        },
                        {
                          Header: "REFERENCE NUMBER",
                          accessor: tableData["reference"],
                        },
                        { Header: "DATE OF BIRTH", accessor: tableData["dob"] },
                        { Header: "AGE", accessor: tableData["age"] },
                        { Header: "PIN CODE", accessor: tableData["pincode"] },
                        { Header: "UID", accessor: "N/A" },
                        { Header: "PAN", accessor: "N/A" },
                        {
                          Header: "RATION CARD",
                          accessor: tableData["ration_card"],
                        },
                        { Header: "VOTER ID", accessor: tableData["voter_id"] },
                        {
                          Header: "MOBILE NUMBER",
                          accessor: tableData["mobile_number"],
                        },
                        {
                          Header: "BORROWER",
                          accessor: tableData["borrower"],
                        },
                        {
                          Header: "CO-BORROWER",
                          accessor: tableData["coborrower"],
                        },
                        {
                          Header: "CLIENT SCORE",
                          accessor: tableData["client_score"],
                        },
                        {
                          Header: "SPOUSE SCORE",
                          accessor: tableData["spouse_score"],
                        },
                      ],
                    },
                  ],
                  [tableData]
                )}
              />
              <div
                className="my-4"
                style={{
                  textAlign: "left",
                  boxShadow: "rgba(21, 92, 171, 0.16) 0px 1px 4px",
                  backgroundColor: "#FFF",
                  padding: "32px",
                }}
              >
                <div
                  className="section_heading py-2 mb-4"
                  style={{
                    color: "black",
                  }}
                >
                  SUMMARY
                </div>
                <BasicTable
                  columns={[
                    {
                      Header: "ACCOUNT CATEGORY",
                      accessor: "account_category",
                    },
                    {
                      Header: "OUTSTANDING",
                      accessor: "outstanding",
                    },
                    {
                      Header: "OVERDUE",
                      accessor: "overdue",
                    },
                    {
                      Header: "WRITE-OFF",
                      accessor: "write_off",
                    },
                  ]}
                  memoTableData={useMemo(
                    () => [
                      {
                        account_category: "PRAYAS / ANANYA / AVANTI",
                        outstanding:
                          tableData["prayas_ananya_avanti_current_balance"],
                        overdue: tableData["prayas_ananya_avanti_overdue"],
                        write_off: tableData["prayas_ananya_avanti_write_off"],
                      },
                      {
                        account_category: "MFI OUTSTANDING",
                        outstanding: tableData["mfi_outstanding"],
                        overdue: tableData["mfi_overdue"],
                        write_off: tableData["mfi_write_off"],
                      },
                      {
                        account_category: "RETAIL UNSECURED (RU)",
                        outstanding:
                          tableData["retail_unsecured_ru_outstanding"],
                        overdue: tableData["ru_overdue"],
                        write_off: tableData["ru_write_off"],
                      },
                      {
                        account_category: "RETAIL SECURED (RS + LTS)",
                        outstanding:
                          tableData["retail_secured_rs_lts_outstanding"],
                        overdue: tableData["rs_lts_overdue"],
                        write_off: tableData["rs_lts_write_off"],
                      },
                      {
                        account_category: "TOTAL CONSIDERED AMOUNT",
                        outstanding: tableData["total_considered_outstanding"],
                        overdue: tableData["total_considered_overdue"],
                        write_off: tableData["total_considered_write_off"],
                      },
                      {
                        account_category:
                          "TOTAL AMOUNT [INCLUDING EXCLUDED LOANS]",
                        outstanding:
                          tableData[
                            "total_outstanding_including_excluded_loans"
                          ],
                        overdue:
                          tableData[
                            "total_overdue_including_excluded_loans_field"
                          ],
                        write_off:
                          tableData[
                            "total_write_off_including_excluded_loans_field"
                          ],
                      },
                    ],
                    [tableData]
                  )}
                />
              </div>

              <DataBox
                userAccess={userAccess}
                loanPackData={loanPackData}
                tableData={tableData}
                data={useMemo(
                  () => [
                    {
                      Heading: "LOAN PRODUCT",
                      heading_bgcolor: "#31859b",
                      cells: [
                        {
                          Header: "MAXIMUM LOAN ELIGIBILITY",
                          accessor: tableData["maximum_loan_eligibility"],
                        },
                        {
                          Header: "LOAN AMOUNT OVERRIDE",
                          accessor: "select",
                          key: "loan_amount_override",
                          value: tableData["loan_amount_override"],
                        },
                        {
                          Header: "FINAL LOAN AMOUNT",
                          accessor: tableData["final_loan_amount"],
                        },
                        {
                          Header: "TENURE ELIGIBILITY",
                          accessor: tableData["tenure_eligibility"],
                        },
                        {
                          Header: "TENURE OVERRIDE (MANUAL)",
                          accessor: "select",
                          key: "tenure_override_manual_field",
                          value: tableData["tenure_override_manual_field"],
                        },
                        {
                          Header: "INSTALLMENT AMOUNT",
                          accessor: tableData["installment_amount"],
                        },
                      ],
                    },
                  ],
                  [tableData]
                )}
                setTableData={setTableData}
                setIsRecalculate={setIsRecalculate}
              />

              {/* here */}
              <div
                className="mt-4"
                style={{
                  textAlign: "left",
                  boxShadow: "rgba(21, 92, 171, 0.16) 0px 1px 4px",
                  backgroundColor: "#FFF",
                  padding: "32px",
                }}
              >
                <div className="row">
                  <div className="col-6">
                    <div className="data_heading mb-1" style={{}}>
                      TOTAL NO. OF TRACKS EXCLUDED
                    </div>
                    <div className="data_content" style={{}}>
                      {tableData["total_no_of_loans_excluded"]}
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="data_heading mb-1" style={{}}>
                      TOTAL NO. OF (RS + LTS) EXCLUDED
                    </div>
                    <div className="data_content" style={{}}>
                      {tableData["total_no_of_rs_lts_excluded"]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-between my-4">
              <div
                className=""
                style={{
                  textAlign: "left",
                  boxShadow: "rgba(21, 92, 171, 0.16) 0px 1px 4px",
                  backgroundColor: "#FFF",
                  padding: "32px",
                  height: "100%",
                }}
              >
                <div className="section_heading py-2 mb-4" style={{}}>
                  DECISION
                </div>
                <BasicTable
                  columns={useMemo(
                    () => [
                      {
                        Header: "Parameter",
                        accessor: "parameter",
                        Cell: ({ row }) => {
                          return (
                            <div className="data_heading" style={{}}>
                              {row.values.parameter}
                            </div>
                          );
                        },
                      },
                      {
                        Header: "Recommendation",
                        accessor: "recommendation",
                        Cell: ({ row }) => {
                          let recommendation =
                            typeof row.values.recommendation === "string"
                              ? row.values.recommendation.toLowerCase()
                              : row.values.recommendation;
                          if (
                            row.values.parameter === "RECOMMENDATION OVERRIDE"
                          ) {
                            return (
                              <select
                                className="data_content"
                                disabled={
                                  tableData["recommendation_override"] === ""
                                    ? userAccess === "Write Access" ||
                                      userAccess === "Admin Access"
                                      ? false
                                      : true
                                    : userAccess === "Admin Access"
                                    ? false
                                    : true
                                }
                                defaultValue={""}
                                value={tableData["recommendation_override"]}
                                onChange={(e) => {
                                  // setTableData({
                                  //   ...tableData,
                                  //   recommendation_override: e.target.value,
                                  // });
                                  setTableData((prevTableData) => {
                                    const updatedTableData = {
                                      ...prevTableData,
                                      recommendation_override: e.target.value,
                                    };
                                    return updatedTableData; // Return the updated state
                                  });
                                }}
                              >
                                <option value={""} disabled={true}>
                                  {"Select Filters"}
                                </option>
                                {[
                                  {
                                    label: "None",
                                    value: "",
                                  },
                                  {
                                    label: "Rejected",
                                    value: "Rejected",
                                  },
                                  {
                                    label: "Approved",
                                    value: "Approved",
                                  },
                                ].map((option, key) => (
                                  <option key={key} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            );
                          } else {
                            return (
                              <button
                                className={
                                  "data_content btn btn-sm  " +
                                  (recommendation === "approve" ||
                                  recommendation === "approved"
                                    ? " btn-success text-white "
                                    : recommendation === "reject" ||
                                      recommendation === "rejected"
                                    ? " btn-danger text-white "
                                    : recommendation ===
                                        "conditionally approve" ||
                                      recommendation ===
                                        "conditionally approved"
                                    ? " btn-primary text-white "
                                    : recommendation === "refer" ||
                                      recommendation === "referred"
                                    ? " btn-warning text-dark "
                                    : " btn-secondary text-white ")
                                }
                                style={{}}
                              >
                                {row.values.recommendation}
                              </button>
                            );
                          }
                        },
                      },
                    ],
                    [tableData]
                  )}
                  memoTableData={useMemo(
                    () => [
                      {
                        parameter: "NO. OF OTHER MFIs",
                        recommendation: tableData["no_of_other_mfis"],
                      },
                      {
                        parameter: "MFI EXPERIENCE",
                        recommendation:
                          Math.round(
                            (tableData["mfi_experience_1"] + Number.EPSILON) *
                              100
                          ) / 100,
                      },
                      {
                        parameter: "MFI + RETAIL UNSECURED EXPERIENCE",
                        recommendation:
                          Math.round(
                            (tableData["mfi_retail_unsecured_experience_1"] +
                              Number.EPSILON) *
                              100
                          ) / 100,
                      },
                      {
                        parameter: "MFI OUTSTANDING",
                        recommendation: tableData["mfi_outstanding_1"],
                      },
                      {
                        parameter: "MFI + RU OUTSTANDING",
                        recommendation: tableData["mfi_ru_outstanding"],
                      },
                      {
                        parameter: "LATEST MFI OVERDUE",
                        recommendation: tableData["latest_mfi_overdue"],
                      },
                      {
                        parameter: "LATEST MFI WRITE-OFF",
                        recommendation: tableData["latest_mfi_write_off"],
                      },
                      {
                        parameter: "NO. OF MFI OVERDUE INSTANCES",
                        recommendation:
                          tableData["no_of_mfi_overdue_instances"],
                      },
                      {
                        parameter: "NO. OF MFI WRITE-OFF INSTANCES",
                        recommendation:
                          tableData["no_of_mfi_write_off_instances"],
                      },
                      {
                        parameter: "LATEST RU OVERDUE",
                        recommendation: tableData["latest_ru_overdue"],
                      },
                      {
                        parameter: "LATEST RU WRITE-OFF",
                        recommendation: tableData["latest_ru_write_off"],
                      },
                      {
                        parameter: "NO. OF RU OVERDUE INSTANCES",
                        recommendation:
                          tableData["no_of_mfi_overdue_instances"],
                      },
                      {
                        parameter: "NO. OF RU WRITE-OFF INSTANCES",
                        recommendation:
                          tableData["no_of_ru_write_off_instances"],
                      },
                      {
                        parameter: "LATEST RS OVERDUE",
                        recommendation: tableData["latest_ru_overdue"],
                      },
                      {
                        parameter: "LATEST LTS OVERDUE",
                        recommendation: tableData["latest_lts_overdue"],
                      },
                      {
                        parameter: "LATEST RS WRITE-OFF",
                        recommendation: tableData["latest_rs_write_off"],
                      },
                      {
                        parameter: "LATEST LTS WRITE-OFF",
                        recommendation: tableData["latest_lts_write_off"],
                      },
                      {
                        parameter: "NO. OF RS + LTS OVERDUE INSTANCES",
                        recommendation:
                          tableData["no_of_rs_lts_overdue_instances"],
                      },
                      {
                        parameter: "NO. OF RS + LTS WRITE-OFF INSTANCES",
                        recommendation:
                          tableData["no_of_rs_lts_write_off_instances"],
                      },
                      { parameter: "FOIR", recommendation: tableData["foir"] },
                      {
                        parameter: "AVERAGE DELAY",
                        recommendation: tableData["average_delay_1"],
                      },
                      {
                        parameter: "PEAK DELAY",
                        recommendation: tableData["peak_delay_1"],
                      },
                      {
                        parameter: "NO. OF INSTANCES OF DELAY",
                        recommendation: tableData["no_of_instances_of_delay"],
                      },
                      {
                        parameter: "OTHER INSTALLMENT LIMIT",
                        recommendation: tableData["installment_amount_decision"],
                      },
                      {
                        parameter: "RECOMMENDATION",
                        recommendation: tableData["recommendation"],
                      },

                      {
                        parameter: "RECOMMENDATION OVERRIDE",
                        recommendation: tableData["recommendation_override"],
                      },
                      {
                        parameter: "FINAL RECOMMENDATION",
                        recommendation: tableData["final_recommendation"],
                      },
                    ],
                    [tableData]
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div
                style={{
                  textAlign: "left",
                  boxShadow: "rgba(21, 92, 171, 0.16) 0px 1px 4px",
                  backgroundColor: "#FFF",
                  padding: "32px",
                }}
              >
                <div className="section_heading py-2 mb-4" style={{}}>
                  TRACK SUMMARY
                </div>
                <BasicTable
                  columns={[
                    {
                      Header: "PARTICULARS",
                      accessor: "particulars",
                    },
                    {
                      Header: "NO. OF TRACKS",
                      accessor: "no_of_tracks",
                    },
                    {
                      Header: "NO. OF TRACKS WITH ISSUES",
                      accessor: "no_of_tracks_with_issue",
                    },
                    {
                      Header: "NO. OF TRACKES W/O ISSUES",
                      accessor: "no_of_tracks_without_issue",
                    },
                    {
                      Header: "CURRENT BALANCE",
                      accessor: "current_balance",
                    },
                    {
                      Header: "CURRENT BALANCE WITH ISSUES",
                      accessor: "current_balance_with_issue",
                    },
                    {
                      Header: "CURRENT BALANCE W/O ISSUES",
                      accessor: "current_balance_without_issue",
                    },
                    {
                      Header: "CALCULATED EMI",
                      accessor: "calculated_emi",
                    },
                  ]}
                  memoTableData={[
                    {
                      particulars: "SELF",
                      no_of_tracks: tableData["self_no_of_tracks"],
                      current_balance: tableData["self_current_balance"],
                      no_of_tracks_with_issue:
                        tableData["self_no_of_tracks_with_issue"],
                      current_balance_with_issue:
                        tableData["self_current_balance_with_issue"],
                      no_of_tracks_without_issue:
                        tableData["self_no_of_tracks_without_issue"],
                      current_balance_without_issue:
                        tableData["self_current_balance_without_issue"],
                      calculated_emi: tableData["self_calculated_emi"],
                    },
                    {
                      particulars: "SPOUSE",
                      no_of_tracks: tableData["spouse_no_of_tracks"],
                      current_balance: tableData["spouse_current_balance"],
                      no_of_tracks_with_issue:
                        tableData["spouse_no_of_tracks_with_issue"],
                      current_balance_with_issue:
                        tableData["spouse_current_balance_with_issue"],
                      no_of_tracks_without_issue:
                        tableData["spouse_no_of_tracks_without_issue"],
                      current_balance_without_issue:
                        tableData["spouse_current_balance_without_issue"],
                      calculated_emi: tableData["spouse_calculated_emi"],
                    },
                    {
                      particulars: "OVERALL",
                      no_of_tracks:
                        tableData["self_no_of_tracks"] +
                        tableData["spouse_no_of_tracks"],
                      current_balance:
                        tableData["self_current_balance"] +
                        tableData["spouse_current_balance"],
                      no_of_tracks_with_issue:
                        tableData["self_no_of_tracks_with_issue"] +
                        tableData["spouse_no_of_tracks_with_issue"],
                      current_balance_with_issue:
                        tableData["self_current_balance_with_issue"] +
                        tableData["spouse_current_balance_with_issue"],
                      no_of_tracks_without_issue:
                        tableData["self_no_of_tracks_without_issue"] +
                        tableData["spouse_no_of_tracks_without_issue"],
                      current_balance_without_issue:
                        tableData["self_current_balance_without_issue"] +
                        tableData["spouse_current_balance_without_issue"],
                      calculated_emi:
                        tableData["self_calculated_emi"] +
                        tableData["spouse_calculated_emi"],
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-12 col-md-6 d-flex">
              <DataBox
                data={useMemo(
                  () => [
                    {
                      Heading: "HISTORY WITH PRAYAS",
                      cells: [
                        {
                          Header: "EXPERIENCE WITH PRAYAS [NO. OF YEARS]",
                          accessor:
                            tableData[
                              "experience_with_prayas_no_of_years_field"
                            ],
                        },
                        {
                          Header: "AVERAGE DELAY",
                          accessor: tableData["average_delay"],
                        },
                        {
                          Header: "PEAK DELAY",
                          accessor: tableData["peak_delay"],
                        },
                        {
                          Header: "NO. OF INSTANCES OF DELAYS",
                          accessor: tableData["no_of_instances_of_delays"],
                        },
                        {
                          Header: "TOTAL NO. OF PRAYAS LOANS",
                          accessor: tableData["total_no_of_prayas_loans"],
                        },
                        {
                          Header: "TOTAL AMOUNT DISBURSED",
                          accessor: tableData["total_amount_disbursed"],
                        },
                        {
                          Header: "LAST LOAN AMOUNT",
                          accessor: tableData["last_loan_amount"],
                        },
                        {
                          Header: "NO. OF LIVE PRAYAS LOANS",
                          accessor: tableData["no_of_live_prayas_loans"],
                        },
                      ],
                    },
                  ],
                  [tableData]
                )}
              />
            </div>
            <div className="col-12 col-md-6 d-flex">
              <DataBox
                data={useMemo(
                  () => [
                    {
                      Heading: "FOIR CALCULATIONS",
                      heading_bgcolor: "#92cddc",
                      cells: [
                        {
                          Header: "INSTALLMENT AMOUNT ACTIVE [OTHER]",
                          accessor:
                            tableData["installment_amount_active_other_field"],
                        },
                        {
                          Header: "NO-OF-OTHER-LENDERS",
                          accessor: tableData["no_of_other_lenders"],
                        },
                        {
                          Header: "ANANYA LOAN AVAILABLE?",
                          accessor: tableData["ananya_loan_available_field"],
                        },
                        {
                          Header: "TOTAL MONTHLY INCOME",
                          accessor: tableData["total_monthly_income"],
                        },
                        {
                          Header: "MAXIMUM INSTALLMENT ELIGIBILITY",
                          accessor:
                            tableData["maximum_installment_eligibility"],
                        },
                        {
                          Header: "FURTHER INSTALLMENT ELIGIBILITY",
                          accessor:
                            tableData["further_installment_eligibility"],
                        },
                        {
                          Header: "FINAL FOIR %",
                          accessor:
                            Math.round(
                              (((tableData[
                                "installment_amount_active_other_field"
                              ] +
                                tableData["installment_amount"]) /
                                tableData["total_monthly_income"]) *
                                100 +
                                Number.EPSILON) *
                                100
                            ) / 100,
                        },
                      ],
                    },
                  ],
                  [tableData]
                )}
              />
            </div>
          </div>

          <div className="row my-4">
            <div className="col-12 my-2">
              <div
                style={{
                  textAlign: "left",
                  boxShadow: "rgba(21, 92, 171, 0.16) 0px 1px 4px",
                  backgroundColor: "#FFF",
                  padding: "32px",
                }}
              >
                <div className="section_heading py-2 mb-4" style={{}}>
                  LOAN-WISE RECORDS
                </div>

                <div id="table" style={{ overflow: "scroll", height: "450px" }}>
                  <BasicTable
                    sorting={true}
                    border={false}
                    borderBottom={false}
                    stickyHeader={true}
                    columns={useMemo(
                      () => [
                        {
                          Header: "TRACK BELONGS TO",
                          Cell: ({ row }) => {
                            console.log(row);
                            let track_belongs_to =
                              row.original.gender === "Female"
                                ? "Self"
                                : row.original.gender === "Male"
                                ? "Spouse"
                                : "";
                            console.log(track_belongs_to);
                            return <>{track_belongs_to}</>;
                          },
                        },
                        {
                          Header: "OWNERSHIP TYPE",
                          accessor: "ownership_type",
                          Cell: ({ row }) => {
                            // console.log(row.values.ownership_type === "");
                            return (
                              <>
                                {row.values.ownership_type !== ""
                                  ? row.values.ownership_type
                                  : "Individual"}
                              </>
                            );
                          },
                        },
                        {
                          Header: "INSTITUTION",
                          accessor: "institution",
                        },
                        {
                          Header: "CLASSIFICATION",
                          accessor: "classification",
                        },
                        {
                          Header: "DB_Date",
                          accessor: "disbursement_date",
                          width: "100px",
                        },
                        {
                          Header: "ACCOUNT STATUS",
                          accessor: "account_status",
                        },
                        {
                          Header: "LOAN AMOUNT",
                          accessor: "disbursed_amount",
                        },
                        {
                          Header: "POS",
                          accessor: "principal_outstanding",
                        },
                        {
                          Header: "OVERDUE AMOUNT",
                          accessor: "overdue_amount",
                        },
                        {
                          Header: "AMOUNT WRITTEN-OFF",
                          accessor: "write_off_amount",
                        },
                        {
                          Header: "ACCOUNT CLOSURE DATE",
                          accessor: "account_closure_date",
                        },
                        {
                          Header: "REPAYMENT FREQUENCY",
                          accessor: "repayment_tenure",
                        },
                        {
                          Header: "LAST REPORTED DATE",
                          accessor: "last_reported",
                        },
                        {
                          Header: "LAST PAYMENT DATE",
                          accessor: "last_payment_date",
                        },
                        {
                          Header: "LOAN CATEGORY (Reported)",
                          accessor: "loan_category",
                        },
                        { Header: "LOAN CYCLE", accessor: "loan_cycle" },
                        { Header: "OVERDUE DAYS", accessor: "overdue_days" },
                        {
                          Header: "INSTALLMENT AMOUNT (Reported)",
                          accessor: "installment_amount",
                        },
                        {
                          Header: "CALCULATED EMI ",
                          accessor: "calculated_emi",
                        },
                        { Header: "REPEAT LOAN?", accessor: "repeat_loan" },
                        { Header: "ACCOUNT TYPE", accessor: "account_type" },
                        {
                          Header: "OVERALL EXCLUSION",
                          accessor: "overall_exclusion",
                        },
                        {
                          Header: "FINAL OVERDUE AMOUNT",
                          accessor: "final_overdue_amount",
                        },
                        {
                          Header: "FINAL WRITE-OFF AMOUNT",
                          accessor: "final_write_off_amount",
                        },
                      ],

                      []
                    )}
                    memoTableData={useMemo(() => loanData, [loanData])}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
              <DataBox
                data={useMemo(
                  () => [
                    {
                      Heading: "RETAIL UNSECURED (RU) DETAILS",
                      heading_bgcolor: "#92cddc",
                      cells: [
                        {
                          Header: "NO. OF OVERDUE RU LENDERS",
                          accessor: tableData["no_of_overdue_ru_lenders"],
                        },
                        {
                          Header: "NO. OF FRESH OVERDUE RU LENDERS",
                          accessor: tableData["no_of_fresh_overdue_ru_lenders"],
                        },
                        {
                          Header: "LATEST RU DEFAULT DATE",
                          accessor:
                            tableData["latest_ru_default_date"] &&
                            tableData["latest_ru_default_date"].split("T")[0],
                        },
                        {
                          Header: "NO. OF WRITTEN OFF RU LENDERS",
                          accessor: tableData["no_of_written_off_ru_lenders"],
                        },
                        {
                          Header: "NO. OF FRESH WRITTEN OFF RU LENDERS",
                          accessor:
                            tableData["no_of_fresh_written_off_ru_lenders"],
                        },
                        {
                          Header: "LATEST RU WRITE-OFF DATE",
                          accessor:
                            tableData["latest_ru_write_off_date"] &&
                            tableData["latest_ru_write_off_date"].split("T")[0],
                        },
                        {
                          Header:
                            "NO. OF FRESH DISBURSEMENTS AFTER LATEST RU DEFAULT DATE",
                          accessor:
                            tableData[
                              "no_of_fresh_disbursements_after_latest_ru_default_date"
                            ] &&
                            tableData[
                              "no_of_fresh_disbursements_after_latest_ru_default_date"
                            ].split("T")[0],
                        },
                        {
                          Header:
                            "NO. OF FRESH DISBURSEMENTS AFTER LATEST RU WRITE-OFF DATE",
                          accessor:
                            tableData[
                              "no_of_fresh_disbursements_after_latest_ru_write_off_date"
                            ] &&
                            tableData[
                              "no_of_fresh_disbursements_after_latest_ru_write_off_date"
                            ].split("T")[0],
                        },
                        {
                          Header: "LATEST RU DISBURSEMENT DATE",
                          accessor:
                            tableData["latest_ru_disbursement_date"] &&
                            tableData["latest_ru_disbursement_date"].split(
                              "T"
                            )[0],
                        },
                      ],
                    },
                  ],
                  [tableData]
                )}
              />

              <div className="mt-4">
                <DataBox
                  data={useMemo(
                    () => [
                      {
                        Heading:
                          "SECURED (RS) + LONG-TERM SECURED (LTS) DETAILS",
                        heading_bgcolor: "#92cddc",
                        cells: [
                          {
                            Header: "NO. OF OVERDUE RS + LTS LENDERS",
                            accessor: tableData["no_of_overdue_rs_lts_lenders"],
                          },
                          {
                            Header: "NO. OF FRESH OVERDUE RS + LTS LENDERS",
                            accessor:
                              tableData["no_of_fresh_overdue_rs_lts_lenders"],
                          },
                          {
                            Header: "LATEST RS DEFAULT DATE",
                            accessor:
                              tableData["latest_rs_default_date"] &&
                              tableData["latest_rs_default_date"].split("T")[0],
                          },
                          {
                            Header: "LATEST LTS DEFAULT DATE",
                            accessor: tableData["latest_lts_default_date"],
                          },
                          {
                            Header:
                              "NO. OF FRESH DISBURSEMENTS AFTER LATEST RS DEFAULT DATE",
                            accessor:
                              tableData[
                                "no_of_fresh_disbursements_after_latest_rs_default_date"
                              ] &&
                              tableData[
                                "no_of_fresh_disbursements_after_latest_rs_default_date"
                              ].split("T")[0],
                          },
                          {
                            Header:
                              "NO. OF FRESH DISBURSEMENTS AFTER LATEST LTS DEFAULT DATE",
                            accessor:
                              tableData[
                                "no_of_fresh_disbursements_after_latest_lts_default_date"
                              ] &&
                              tableData[
                                "no_of_fresh_disbursements_after_latest_lts_default_date"
                              ].split("T")[0],
                          },
                          {
                            Header: "NO. OF WRITTEN OFF RS + LTS LENDERS",
                            accessor:
                              tableData["no_of_written_off_rs_lts_lenders"],
                          },
                          {
                            Header: "NO. OF FRESH WRITTEN OFF RS + LTS LENDERS",
                            accessor:
                              tableData[
                                "no_of_fresh_written_off_rs_lts_lenders"
                              ],
                          },
                          {
                            Header: "LATEST RS WRITE-OFF DATE",
                            accessor:
                              tableData["latest_rs_write_off_date"] &&
                              tableData["latest_rs_write_off_date"].split(
                                "T"
                              )[0],
                          },
                          {
                            Header: "LATEST LTS WRITE-OFF DATE",
                            accessor:
                              tableData["latest_lts_write_off_date"] &&
                              tableData["latest_lts_write_off_date"].split(
                                "T"
                              )[0],
                          },
                          {
                            Header:
                              "NO. OF FRESH DISBURSEMENTS AFTER LATEST RS WRITE-OFF DATE",
                            accessor:
                              tableData[
                                "no_of_fresh_disbursements_after_latest_rs_write_off_date"
                              ] &&
                              tableData[
                                "no_of_fresh_disbursements_after_latest_rs_write_off_date"
                              ].split("T")[0],
                          },
                          {
                            Header:
                              "NO. OF FRESH DISBURSEMENTS AFTER LATEST LTS WRITE-OFF DATE",
                            accessor:
                              tableData[
                                "no_of_fresh_disbursements_after_latest_lts_write_off_date"
                              ] &&
                              tableData[
                                "no_of_fresh_disbursements_after_latest_lts_write_off_date"
                              ].split("T")[0],
                          },
                          {
                            Header: "LATEST RS DISBURSEMENT DATE",
                            accessor:
                              tableData["latest_rs_disbursement_date"] &&
                              tableData["latest_rs_disbursement_date"].split(
                                "T"
                              )[0],
                          },
                          {
                            Header: "LATEST LTS DISBURSEMENT DATE",
                            accessor:
                              tableData["latest_lts_disbursement_date"] &&
                              tableData["latest_lts_disbursement_date"].split(
                                "T"
                              )[0],
                          },
                        ],
                      },
                    ],
                    [tableData]
                  )}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column">
              <DataBox
                data={useMemo(
                  () => [
                    {
                      Heading: "MFI DETAILS",
                      heading_bgcolor: "#92cddc",
                      cells: [
                        {
                          Header: "MFI EXPERIENCE",
                          accessor:
                            Math.round(
                              (tableData["mfi_experience"] + Number.EPSILON) *
                                100
                            ) / 100,
                        },
                        {
                          Header: "RETAIL UNSECURED EXPERIENCE",
                          accessor: tableData["retail_unsecured_experience"],
                        },
                        {
                          Header: "MFI + RETAIL UNSECURED EXPERIENCE",
                          accessor:
                            Math.round(
                              (tableData["mfi_retail_unsecured_experience"] +
                                Number.EPSILON) *
                                100
                            ) / 100,
                        },
                        {
                          Header: "NO. OF MFI LENDERS",
                          accessor: tableData["no_of_mfi_lenders"],
                        },
                        {
                          Header: "NO. OF OVERDUE MFI LENDERS",
                          accessor: tableData["no_of_overdue_mfi_lenders"],
                        },
                        {
                          Header: "NO. OF FRESH OVERDUE MFI LENDERS",
                          accessor:
                            tableData["no_of_fresh_overdue_mfi_lenders"],
                        },
                        {
                          Header: "LATEST MFI DEFAULT DATE",
                          accessor:
                            tableData["latest_mfi_default_date"] &&
                            tableData["latest_mfi_default_date"].split("T")[0],
                        },
                        {
                          Header: "NO. OF WRITTEN OFF MFI LENDERS",
                          accessor: tableData["no_of_written_off_mfi_lenders"],
                        },
                        {
                          Header: "NO. OF FRESH WRITTEN OFF MFI LENDERS",
                          accessor:
                            tableData["no_of_fresh_written_off_mfi_lenders"],
                        },
                        {
                          Header: "LATEST MFI WRITE-OFF DATE	",
                          accessor:
                            tableData["latest_mfi_write_off_date"] &&
                            tableData["latest_mfi_write_off_date"].split(
                              "T"
                            )[0],
                        },
                        {
                          Header:
                            "NO. OF NEW MFI LOANS SANCTIONED IN LAST 2 YEARS AGED AT LEAST 6 MONTHS",
                          accessor:
                            tableData[
                              "no_of_new_mfi_loans_sanctioned_in_last_2_years_aged_at_least_6"
                            ],
                        },
                        {
                          Header: "LATEST MFI DISBURSEMENT DATE",
                          accessor:
                            tableData["latest_mfi_disbursement_date"] &&
                            tableData["latest_mfi_disbursement_date"].split(
                              "T"
                            )[0],
                        },
                      ],
                    },
                  ],
                  [tableData]
                )}
              />

              <div className="my-4">
                <div
                  className=""
                  style={{
                    textAlign: "left",
                    boxShadow: "rgba(21, 92, 171, 0.16) 0px 1px 4px",
                    backgroundColor: "#FFF",
                    padding: "32px",
                  }}
                >
                  <div className="section_heading py-2 mb-4" style={{}}>
                    COMMENTS
                  </div>
                  <BasicTable
                    columns={commentsColumns}
                    memoTableData={commentsMemoTableData}
                  />

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      postData("comment");
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center py-2 pb-3"
                      style={{
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                        fontStyle: "normal",
                      }}
                    >
                      <label htmlFor="comment">Add Comment</label>
                      <input
                        disabled={
                          userAccess === "Write Access" ||
                          userAccess === "Admin Access"
                            ? false
                            : true
                        }
                        type={"text"}
                        className="w-50"
                        id="comment"
                        name="comment"
                        value={comment}
                        autoComplete={"off"}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        required={true}
                      ></input>
                      <button
                        disabled={isDisabled}
                        className="px-3 py-1 fw-semibold "
                        style={{
                          backgroundColor: isDisabled ? "#64748b" : "#000000",
                          color: "white",
                          borderRadius: "4px",
                          transition: "0.3s",
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div>
                <div
                  className=""
                  style={{
                    textAlign: "left",
                    boxShadow: "rgba(21, 92, 171, 0.16) 0px 1px 4px",
                    backgroundColor: "#FFF",
                    padding: "32px",
                  }}
                >
                  <div className="section_heading py-2 mb-4" style={{}}>
                    ATTACHMENTS
                  </div>
                  <BasicTable
                    columns={[
                      {
                        Header: "Attachment",
                        accessor: "attachment_name",
                      },
                      {
                        Header: "Options",
                        Cell: ({ row }) => {
                          let url = row.original.attachment_url;
                          return (
                            url && (
                              <div className="text-center">
                                <a href={url} target={"_blank"}>
                                  <button
                                    className={
                                      "btn text-white fw-bold btn-sm btn-success"
                                    }
                                    style={{ minWidth: "100px" }}
                                  >
                                    {"View"}
                                  </button>
                                </a>
                              </div>
                            )
                          );
                        },
                      },
                    ]}
                    memoTableData={useMemo(
                      () =>
                        attachments.length !== 0
                          ? attachments
                          : [{ attachment_name: "No Attachments" }],
                      [attachments]
                    )}
                  />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      postData("attachment");
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center py-2"
                      style={{
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                        fontStyle: "normal",
                      }}
                    >
                      <label>
                        Attachments <br />( NOC / Settlement letter /
                        <br />
                        Account Statement)
                      </label>
                      <input
                        disabled={
                          userAccess === "Write Access" ||
                          userAccess === "Admin Access"
                            ? false
                            : true
                        }
                        type={"file"}
                        className="w-25"
                        onChange={(e) => {
                          setAttachmentFile(e.target.files);
                        }}
                        multiple={true}
                      ></input>
                      <SelectInput
                        disabled={
                          userAccess === "Write Access" ||
                          userAccess === "Admin Access"
                            ? false
                            : true
                        }
                        label={"Document Type"}
                        wrapperClassName={"w-25 h-100"}
                        className={
                          "w-100 h-100 py-1 border border-dark rounded"
                        }
                        value={attachmentFileType}
                        onChange={(e) => {
                          setAttachmentFileType(e.target.value);
                        }}
                        options={[
                          {
                            label: "NOC",
                            value: "NOC",
                          },
                          {
                            label: "Settlement letter",
                            value: "settlement_letter",
                          },
                          {
                            label: "Account Statement",
                            value: "account_statement",
                          },
                          {
                            label: "Aadhar Card",
                            value: "aadhar_card",
                          },
                        ]}
                      />
                      <button
                        disabled={isDisabled}
                        className="px-3 py-1 fw-semibold "
                        style={{
                          backgroundColor: isDisabled ? "#64748b" : "#000000",
                          color: "white",
                          borderRadius: "4px",
                          transition: "0.3s",
                        }}
                      >
                        ADD
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div></div>
            </div>
          </div>

          <div
            className="d-flex align-items-center gap-3 py-2"
            style={{ height: "90px", fontSize: "12px" }}
          >
            <button
              disabled={isDisabled}
              className="px-3 py-2 fw-semibold"
              style={{
                backgroundColor: isDisabled ? "#64748b" : "#000000",
                color: "white",
                borderRadius: "4px",
                transition: "0.3s",
                cursor: "pointer",
              }}
              onClick={updateData}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CBOutputPage;

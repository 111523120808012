export const AccountTypeExclusionStyles = (column) => {
  return {
    textAlign: ["Account Type Exclusion"].includes(column.Header)
      ? "center"
      : "",
    // maxWidth: column.Header === "-" ? "120px" : "",
    // minWidth: column.Header === "-" ? "30px" : "145px",
    // width: column.Header === "Manually Entered" ? "80px" : "auto",
    borderWidth: "1px",
    backgroundColor:
      column.Header === "Account Type Exclusion"
        ? "#0070c0"
        : [
            "Account Type",
            "Outstanding Exclusion",
            "OS Exclusion - Limit",
            "OD Exclusion",
            "OD Exclusion - Limit",
            "Write-off Exclusion",
            "Write-off Exclusion - Limit",
            "Fresh Disbursements Exclusions",
            "Classification",
            "Max Loan Amount #1",
            "Max Loan Amount #2",
            "Max Loan Amount #3",
            "Interest Rate %",
            "Calculated EMI Applicability",
            "Tenure Category",
            "Total Outstanding",
            "Average Outstanding per Live Loan",
            "No. of Live Loans",
          ].includes(column.Header)
        ? "#c6d9f0"
        : "#f2f2f2",
    color: ["Account Type Exclusion"].includes(column.Header) ? "white" : "",
  };
};

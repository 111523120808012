import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import API from "../components/api";
import { getEmployeeUserAccess } from "../urls";

const Protected = ({ user, updateUser, setUserAccess }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      API({
        ...getEmployeeUserAccess,
        onSuccess: (res) => {
          let data = res.data.data;
          updateUser(res.data.data.employee_name, "employee_name");
          setUserAccess(data["access"]);
        },
        onError: (error) => {
          // alert(error);
        },
      });
    }
  }, [navigate]);

  return (
    <>
      <Outlet></Outlet>{" "}
    </>
  );
};

export default Protected;

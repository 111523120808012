import React from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
// import Button from './button'

function Modal({ modalId, size, bodyClassName, title, body, footer, backdrop, onModalLoad = () => {} }) {
	const [isWindowAvaliable, setIsWindowAvaliable] = React.useState(false)
	React.useEffect(() => {
		setIsWindowAvaliable(true)
		setTimeout(() => {
			onModalLoad()
		}, 1000)
	}, [])

	return (
		<>
			{isWindowAvaliable &&
				ReactDOM.createPortal(
					<div id={modalId} className='modal fade' data-bs-backdrop={backdrop ? backdrop : true} tabIndex={-1}>
						<div
							className={classnames('modal-dialog modal-dialog-centered modal-dialog-scrollable', {
								'modal-lg': size && size === 'lg',
								'modal-sm': size && size === 'sm',
							})}
						>
							<div className='modal-content border-0 shadow'>
								{React.isValidElement(title) === true ? (
									title
								) : title !== undefined ? (
									<div className='modal-header p-3 border-0'>
										<h5 className='modal-title m-0'>{title}</h5>
										{/* <Button
											type='button'
											variant=''
											className='btn-close'
											dismiss='modal'
											customComponent={{
												'aria-label': 'Close',
											}}
										/> */}
									</div>
								) : (
									<></>
								)}

								{body !== undefined ? (
									<div className={classnames('modal-body pt-0', bodyClassName)}>{body}</div>
								) : (
									<></>
								)}

								{footer !== undefined ? <div className='modal-footer'>{footer}</div> : <></>}
							</div>
						</div>
					</div>,
					document.getElementById('modal-container')
				)}
		</>
	)
}

export default Modal

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import API from "../components/api";
import { getToken, resetPassword } from "../urls";

const ResetPasswordPage = () => {
  const [formData, setFormData] = useState();
  const navigate = useNavigate();

  function onChange(newValue, id) {
    let tempVal = { ...formData };
    tempVal[id] = newValue;
    setFormData(tempVal);
  }
  function onSubmit(e) {
    e.preventDefault();
    let submitFormData = new FormData();

    // submitFormData.append("username", formData.username);
    submitFormData.append("password", formData.password);
    submitFormData.append("confirm_password", formData.confirm_password);
    if (formData.password === formData.confirm_password)
      API({
        ...resetPassword,
        body: submitFormData,
        isFormData: true,
        onSuccess: (res) => {
          if (res.data.data.reset_successful) {
            alert("Your password reset has been successfully completed.")
            navigate("/login");
          }
        },
        onError: (error) => {
          alert(error);
        },
      });
  }
  const InputClassName = `w-100 my-2 fs-5 py-2 px-2`;
  const InputStyle = {
    flexBasis: "40%",
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="border mx-auto mt-5 p-5 col-lg-4 col-md-6 col-sm-6"
          style={{
            width: "30%",
            border: "3px solid rgb(158 173 186)",
            borderRadius: "26px",
            // boxShadow: "10px 10px 20px #bebebe,-10px -10px 20px #ffffff",
            boxShadow: "0 4px 35px rgba(0,0,0,.1)",
          }}
        >
          <form
            onSubmit={onSubmit}
            id="login"
            className="w-100 d-flex flex-column gap-3 align-items-start p-2"
          >
            <h3>Reset Password</h3>
            <div className="w-100 fs-5 fw-semibold gap-4">
              <input
                type={"password"}
                className={InputClassName}
                style={InputStyle}
                id={"password"}
                value={formData ? formData["password"] : ""}
                onChange={(e) => {
                  onChange(e.target.value, e.target.id);
                }}
                placeholder="Password"
                required={true}
              ></input>
            </div>
            <div className="w-100 fs-5 fw-semibold gap-4">
              <input
                type={"password"}
                className={InputClassName}
                style={InputStyle}
                id={"confirm_password"}
                value={formData ? formData["confirm_password"] : ""}
                onChange={(e) => {
                  onChange(e.target.value, e.target.id);
                }}
                placeholder={"Confirm Password"}
                required={true}
              ></input>
            </div>

            <div className="w-100 gap-4">
              <button
                type="submit"
                className="w-100 fs-5 text-center p-2"
                style={{
                  color: "white",
                  backgroundColor: "#0a66c2",
                  border: "0",
                  borderRadius: "50px",
                  cursor: "pointer",
                  alignSelf: "end",
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;

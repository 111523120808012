import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../components/api";
import { uploadFile, cbEngine } from "../urls";

const EquifaxFormPage = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [filesDataUploading, setFilesDataUploading] = React.useState(false);
  const [filesUploadProgress, setFilesUploadProgress] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);

  const navigate = useNavigate();

  function runCBEngine(e) {
    e.preventDefault();
    setFilesDataUploading(true);
    let formData = new FormData();
    Object.keys(attachment["equifax"]).forEach((i) => {
      formData.append(`equifax_file_${i}`, attachment["equifax"][i]);
    });
    formData.append(
      `equifax_file_count`,
      Object.keys(attachment["equifax"]).length
    );
    formData.append("cbcons_file", attachment["cbcons"][0]);

    API({
      ...cbEngine,
      body: formData,
      isFormData: true,
      onSuccess: (res) => {
        navigate("/");
      },
      onError: (error) => {
        alert(error);
      },
      uploadProgress: (progress) => {
        setFilesUploadProgress(progress);
        if (progress == "100") {
          setShowMessage(true);
        }
      },
    });
  }

  return (
    <>
      <h6
        className={showMessage ? "alert alert-success" : "d-none"}
        role="alert"
      >
        {" "}
        Your files are uploaded successfully. CB Engine is running Please Wait.
      </h6>

      <div
        className="d-flex align-items-center"
        style={{ height: "calc(100% - 80px)", backgroundColor: "#f5f8fd" }}
      >
        <div
          className="w-50 m-auto p-5"
          style={{
            height: "auto",
            boxShadow: "0 4px 35px rgba(0,0,0,.1)",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "14px",
            fontWeight: "700",
            fontStyle: "normal",
            borderRadius: "26px",
          }}
        >
          <form onSubmit={runCBEngine}>
            <div className="mb-3" style={{ fontSize: "28px" }}>
              CB ENGINE
            </div>

            <label className="mb-1" style={{ fontSize: "16px" }}>
              Upload the Equifax file
            </label>
            <div className="d-flex justify-content-between align-items-center ">
              <input
                type={"file"}
                required={true}
                className="me-1 p-1"
                onChange={(e) => {
                  // console.log(e.target.files, e.target.files[0].name);
                  setAttachment({ ...attachment, equifax: e.target.files });
                }}
                multiple={true}
                style={{ flexGrow: "1", height: "100%" }}
              ></input>
            </div>

            <label className="mb-1" style={{ fontSize: "16px" }}>
              Upload the CB Cons file
            </label>
            <div className="d-flex justify-content-between align-items-center ">
              <input
                type={"file"}
                required={true}
                className="me-1 p-1"
                onChange={(e) => {
                  // console.log(e.target.files, e.target.files[0].name);
                  setAttachment({ ...attachment, cbcons: e.target.files });
                }}
                multiple={false}
                style={{ flexGrow: "1" }}
              ></input>
            </div>
            <button
              disabled={isDisabled}
              className="d-block px-3 py-1 fw-semibold my-2 mt-4 ms-auto btn btn-success"
              style={{
                color: "white",
                borderRadius: "4px",
                transition: "0.3s",
              }}
            >
              GENERATE RESULTS
            </button>
          </form>
          <div
            className={filesDataUploading ? "progress mt-3" : "d-none"}
            role="progressbar"
          >
            <div
              className="progress-bar"
              style={{ width: `${filesUploadProgress}%` }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EquifaxFormPage;

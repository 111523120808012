export const RepeatClientStaticData=[
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "MFI Experience including proposed loan",
      conditional_approval_value1_static: "<",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1:
        "Total MFI Outstanding including Proposed Loan",
      specific_exclusion1_value1_static: "<=",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "",
      specific_exclusion_2_value1a_static: "",
      specific_exclusion_2_condition2a: "",
      specific_exclusion_2_value2a_static: "",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "MFI Experience including proposed loan",
      conditional_approval_value1_static: "<",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "",
      specific_exclusion_2_value1a_static: "",
      specific_exclusion_2_condition2a: "",
      specific_exclusion_2_value2a_static: "",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "MFI Experience including proposed loan+retail unsecured experience",
      conditional_approval_value1_static: "<",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "",
      specific_exclusion_2_value1a_static: "",
      specific_exclusion_2_condition2a: "",
      specific_exclusion_2_value2a_static: "",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: ">",
      conditional_approval_approval_limit_static: "",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static:
        "",
      refer_static: ">",
      reject_static: "<=",
      specific_exclusion1_condition1: "Report Date (-) Last Reported Date",
      specific_exclusion1_value1_static: "<=",
      specific_exclusion2_value2_static: "<=",
      specific_exclusion2_condition2: "Overdue Days",
      specific_exclusion_2_condition1a: "No. of Years since Default",
      specific_exclusion_2_value1a_static: ">=",
      specific_exclusion_2_condition2a: "No. of MFI Loans Disbursed in last 2 years",
      specific_exclusion_2_value2a_static: ">=",
      specific_exclusion_2_condition3a: "Vintage of the new MFI loans disbursed [No. of Months]",
      specific_exclusion_2_value3a_static: ">=",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: ">",
      conditional_approval_approval_limit_static: "",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: ">",
      reject_static: "<=",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "No. of Years since Default",
      specific_exclusion_2_value1a_static: ">=",
      specific_exclusion_2_condition2a: "No. of MFI Loans Disbursed in last 2 years",
      specific_exclusion_2_value2a_static: ">=",
      specific_exclusion_2_condition3a: "Vintage of the new MFI loans disbursed [No. of Months]",
      specific_exclusion_2_value3a_static: ">=",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "No.of Years since the Default Date (in all the instances)",
      conditional_approval_value1_static: ">",
      conditional_approval_condition_title2_static: "Overdue Amount",
      conditional_approval_value2_static: "<=",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "No. of Years since Default",
      specific_exclusion_2_value1a_static: ">=",
      specific_exclusion_2_condition2a: "No. of MFI Loans Disbursed in last 2 years",
      specific_exclusion_2_value2a_static: ">=",
      specific_exclusion_2_condition3a: "Vintage of the new MFI loans disbursed [No. of Months]",
      specific_exclusion_2_value3a_static: ">=",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "No.of Years since the Write-off Date (in all the instances)",
      conditional_approval_value1_static: ">",
      conditional_approval_condition_title2_static: "Amount Written off",
      conditional_approval_value2_static: "<=",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "No. of Years since Default",
      specific_exclusion_2_value1a_static: ">=",
      specific_exclusion_2_condition2a: "No. of MFI Loans Disbursed in last 2 years",
      specific_exclusion_2_value2a_static: ">=",
      specific_exclusion_2_condition3a: "Vintage of the new MFI loans disbursed [No. of Months]",
      specific_exclusion_2_value3a_static: ">=",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: ">",
      conditional_approval_approval_limit_static: "",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static:
        "",
      refer_static: ">=",
      reject_static: "<",
      specific_exclusion1_condition1: "Report Date (-) Last Reported Date",
      specific_exclusion1_value1_static: "<=",
      specific_exclusion2_value2_static: "<=",
      specific_exclusion2_condition2: "Overdue Days",
      specific_exclusion_2_condition1a:
        "Experience with Prayas [No. of Years]",
      specific_exclusion_2_value1a_static: ">",
      specific_exclusion_2_condition2a: "Closed Prayas Loans",
      specific_exclusion_2_value2a_static: ">",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "Disbursement Amount",
      selective_category_2_parameter: "Disbursement Amount",
      selective_category_3_parameter: "Disbursement Amount",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: ">",
      conditional_approval_approval_limit_static: "",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: ">=",
      reject_static: "<",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a:
        "Experience with Prayas [No. of Years]",
      specific_exclusion_2_value1a_static: ">",
      specific_exclusion_2_condition2a: "Closed Prayas Loans",
      specific_exclusion_2_value2a_static: ">",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "Disbursement Amount",
      selective_category_2_parameter: "Disbursement Amount",
      selective_category_3_parameter: "Disbursement Amount",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "No.of Years since the Default Date (in all the instances)",
      conditional_approval_value1_static: ">",
      conditional_approval_condition_title2_static: "Overdue Amount",
      conditional_approval_value2_static: "<=",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "<=",
      specific_exclusion2_condition2: "Overdue Days",
      specific_exclusion_2_condition1a: "",
      specific_exclusion_2_value1a_static: "",
      specific_exclusion_2_condition2a: "",
      specific_exclusion_2_value2a_static: "",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "No.of Years since the Write-off Date (in all the instances)",
      conditional_approval_value1_static: ">",
      conditional_approval_condition_title2_static: "Amount Written off",
      conditional_approval_value2_static: "<=",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "",
      specific_exclusion_2_value1a_static: "",
      specific_exclusion_2_condition2a: "",
      specific_exclusion_2_value2a_static: "",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: ">",
      conditional_approval_approval_limit_static: "",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static:
        "",
      refer_static: ">=",
      reject_static: "<",
      specific_exclusion1_condition1: "Report Date (-) Last Reported Date",
      specific_exclusion1_value1_static: "<=",
      specific_exclusion2_value2_static: "<=",
      specific_exclusion2_condition2: "Overdue Days",
      specific_exclusion_2_condition1a:
        "Experience with Prayas [No. of Years]",
      specific_exclusion_2_value1a_static: ">",
      specific_exclusion_2_condition2a: "Closed Prayas Loans",
      specific_exclusion_2_value2a_static: ">",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "Disbursed Amount",
      selective_category_2_parameter: "Disbursed Amount",
      selective_category_3_parameter: "Disbursed Amount",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: ">",
      conditional_approval_approval_limit_static: "",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: ">=",
      reject_static: "<",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a:
        "Experience with Prayas [No. of Years]",
      specific_exclusion_2_value1a_static: ">",
      specific_exclusion_2_condition2a: "Closed Prayas Loans",
      specific_exclusion_2_value2a_static: ">",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "Disbursed Amount",
      selective_category_2_parameter: "Disbursed Amount",
      selective_category_3_parameter: "Disbursed Amount",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: ">",
      conditional_approval_approval_limit_static: "",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static:
        "",
      refer_static: ">=",
      reject_static: "<",
      specific_exclusion1_condition1: "Report Date (-) Last Reported Date",
      specific_exclusion1_value1_static: "<=",
      specific_exclusion2_value2_static: "<=",
      specific_exclusion2_condition2: "Overdue Days",
      specific_exclusion_2_condition1a:
        "Experience with Prayas [No. of Years]",
      specific_exclusion_2_value1a_static: ">",
      specific_exclusion_2_condition2a: "Closed Prayas Loans",
      specific_exclusion_2_value2a_static: ">",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: ">",
      conditional_approval_approval_limit_static: "",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static:
        "",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: ">=",
      reject_static: "<",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "Experience with Prayas [No. of Years]",
      specific_exclusion_2_value1a_static: ">",
      specific_exclusion_2_condition2a: "Closed Prayas Loans",
      specific_exclusion_2_value2a_static: ">",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static: "Overdue Amount",
      conditional_approval_value2_static: "<=",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "<=",
      specific_exclusion2_condition2: "Overdue Days",
      specific_exclusion_2_condition1a: "",
      specific_exclusion_2_value1a_static: "",
      specific_exclusion_2_condition2a: "",
      specific_exclusion_2_value2a_static: "",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static:
        "",
      conditional_approval_value1_static: "",
      conditional_approval_condition_title2_static: "Amount Written off",
      conditional_approval_value2_static: "<=",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: ">",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "",
      specific_exclusion_2_value1a_static: "",
      specific_exclusion_2_condition2a: "",
      specific_exclusion_2_value2a_static: "",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    {
      approve_static: "<=",
      conditional_approval_approval_limit_static: "<=",
      conditional_approval_condition_title1_static: "Monthly Household Income",
      conditional_approval_value1_static: ">",
      conditional_approval_condition_title2_static: " ",
      conditional_approval_value2_static: "",
      conditional_approval_parameter_condition_title_static: "",
      refer_static: "<=",
      reject_static: "=",
      specific_exclusion1_condition1: "",
      specific_exclusion1_value1_static: "",
      specific_exclusion2_value2_static: "",
      specific_exclusion2_condition2: "",
      specific_exclusion_2_condition1a: "",
      specific_exclusion_2_value1a_static: "",
      specific_exclusion_2_condition2a: "",
      specific_exclusion_2_value2a_static: "",
      specific_exclusion_2_condition3a: "",
      specific_exclusion_2_value3a_static: "",
      selective_category_1_parameter: "",
      selective_category_2_parameter: "",
      selective_category_3_parameter: "",
      selective_category_4_parameter: "",
      selective_category_5_parameter: "",
      selective_category_6_parameter: "",
      selective_category_7_parameter: "",
    },
    { approve_static: "<=", refer_static: "<=", reject_static: ">" },
    { approve_static: "<", refer_static: "<", reject_static: ">=" },
    { approve_static: "<=", refer_static: "<", reject_static: ">=" },
  ]
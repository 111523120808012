import React from "react";
import { useTable, useSortBy } from "react-table";
import BTable from "react-bootstrap/Table";
import { eye, filter, reset, sort, sortDown, sortUp } from "../images";

const BasicTable = ({
  columns,
  memoTableData,
  tableHeadingStyles,
  border = true,
  borderBottom = true,
  stickyHeader = false,
  sorting = false,
}) => {
  const tableInstance = useTable(
    {
      columns: columns,
      data: memoTableData,
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
  } = tableInstance;
  return (
    <div className={""}>
      <BTable
        {...getTableProps()}
        className={"m-0"}
        style={{
          borderCollapse: stickyHeader ? "separate" : "collapse",
          borderSpacing: stickyHeader ? "0" : "collapse",
        }}
      >
        <thead
          className=""
          style={{
            position: stickyHeader && "sticky",
            top: stickyHeader && "0px",
            zIndex: stickyHeader && "1",
          }}
        >
          {headerGroups.map((headerGroup) => (
            <tr className="" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="py-2 pe-2"
                    style={{
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontSize: "10px",
                      fontWeight: "700",
                      fontStyle: "normal",
                      lineHeight: "16px",
                      borderWidth: "0px",
                      verticalAlign: "top",
                      textAlign: "left",
                      backgroundColor: "#e1efff",
                      color: "black",
                      width: ["Options"].includes(column.Header)
                        ? "20%"
                        : ["INSTITUTION"].includes(column.Header)
                        ? "20%"
                        : ["WRITE-OFF"].includes(column.Header)
                        ? "20%"
                        : ["DB Date", "CALCULATED EMI "].includes(column.Header)
                        ? "10%"
                        : "auto",
                      // outline: stickyHeader && "1px solid black",
                    }}
                  >
                    {/* {column.render("Header")} */}
                    <div
                      className="d-flex"
                      style={{
                        cursor: ["VIEW"].includes(column.Header)
                          ? "auto"
                          : "pointer",
                      }}
                    >
                      <div className="me-2">{column.render("Header")}</div>
                      <div
                        style={{
                          display: sorting ? "block" : "none",
                        }}
                      >
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={sortDown}></img>
                          ) : (
                            <img src={sortUp}></img>
                          )
                        ) : (
                          <img src={sort}></img>
                        )}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          style={{
            fontFamily: "'Source Sans Pro', sans-serif",
            fontSize: "11px",
            lineHeight: "17px",
            fontWeight: "400",
          }}
        >
          {rows.map((row, i) => {
            let backgroundColor = "";
            let color = "";
            let overall_exclusion = row.values.overall_exclusion;
            let final_write_off_amount = row.values.final_write_off_amount;
            let final_overdue_amount = row.values.final_overdue_amount;
            let classification = row.values.classification;
            console.log();
            if (
              overall_exclusion === "Yes" &&
              ((final_overdue_amount > 0 && final_overdue_amount !== "") ||
                (final_write_off_amount > 0 && final_write_off_amount !== ""))
            ) {
              backgroundColor = "#FCE9DA";
              color = "red";
            } else if (
              (final_overdue_amount > 0 && final_overdue_amount !== "") ||
              (final_write_off_amount > 0 && final_write_off_amount !== "")
            ) {
              backgroundColor = "#C00000";
              color = "white";
            } else if (classification === "Long-term Secured") {
              backgroundColor = "black";
              color = "white";
            } else if (classification === "Secured") {
              backgroundColor = "#808080";
              color = "white";
            } else if (classification === "Unsecured") {
              backgroundColor = "#D9D9D9";
              color = "black";
            } else if (classification === "MFI") {
              backgroundColor = "white";
              color = "black";
            }
            prepareRow(row);
            return (
              <tr
                id={row.original.row_id}
                {...row.getRowProps()}
                style={{
                  backgroundColor:
                    backgroundColor !== "" ? backgroundColor : "#f5f9ff",
                  color: color !== "" ? color : "black",
                  borderStyle: "none",
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className=""
                      style={{
                        padding: "4px 4px 8px 8px",
                        // color: "#122248",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </div>
  );
};

export default BasicTable;

import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";

const Access = ({ access, userAccess }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (userAccess && userAccess !== access) {
      navigate("/");
    }
  }, [navigate, userAccess]);

  return (
    <>
      <Outlet></Outlet>{" "}
    </>
  );
};

export default Access;

import React from "react";

const DateInput = ({
  options,
  onChange,
  onBlur,
  value,
  id,
  name,
  label,
  wrapperClassName,
  className,
  style,
  required,
  min,
}) => {
  let max = "";
  if (min) {
    let minDateObj = new Date(min);
    let maxDateObj = new Date(minDateObj.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding 7 days in milliseconds
    max = maxDateObj.toISOString().split("T")[0];
  }
  return (
    <div className={" " + wrapperClassName}>
      <div className="px-0 d-flex flex-column  gap-0">
        <div className="data_content" style={{ lineHeight: "20px" }}>
          {label}
        </div>
        <input
          type="date"
          className="p-2"
          style={{
            fontSize: "15px",
            width: "auto",
            color: value ? "black" : "grey",
          }}
          value={value || ""}
          placeholder={"placeholder"}
          onChange={onChange}
          required={required}
          min={min}
          max={max}
        ></input>
      </div>
    </div>
  );
};

export default DateInput;

import React from "react";
import API from "./api";
import Modal from "./modal";
import { createUser, updateUser } from "../urls";
import { Modal as BModal } from "bootstrap";

function InputGroup({ type, id, name, label, value }) {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label" style={{ fontSize: "14px" }}>
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        autoComplete="off"
        required={true}
        className="form-control"
        value={value}
        disabled={true}
      ></input>
    </div>
  );
}

function AccountCreateFormModal() {
  const [value, setValue] = React.useState({});
  const [isAdd, setIsAdd] = React.useState(false);

  React.useEffect(() => {}, []);

  function onModalLoad() {
    let elem = document.getElementById("add_user_modal");
    elem.addEventListener("show.bs.modal", (event) => {
      setIsAdd(JSON.parse(elem.getAttribute("data-isAdd")));
      setValue(JSON.parse(elem.getAttribute("data-selectedData")));
    });
  }

  function createOrUpdateUser(e) {
    e.preventDefault();

    let userData = { ...value },
      url = createUser;

    if (isAdd === false) {
      url = updateUser;
    }
    API({
      ...url,
      body: userData,
      onSuccess: (res) => {
        let elem = document.getElementById("add_user_modal");
        BModal.getOrCreateInstance(elem).hide();
      },
      onError: (error) => {
        alert(error.response.data.message);
      },
    });
  }

  function onChange(newValue, id) {
    let tempVal = { ...value };
    tempVal[id] = newValue;
    setValue(tempVal);
  }

  return (
    <Modal
      modalId="add_user_modal"
      title={`Create User`}
      onModalLoad={onModalLoad}
      body={
        <>
          <div>
            <form onSubmit={createOrUpdateUser}>
              <InputGroup
                type={"text"}
                id={"employee_id"}
                name={"employee_id"}
                label={"Employee ID"}
                value={value.employee_id}
              />
              <InputGroup
                type={"text"}
                id={"employee_name"}
                name={"employee_name"}
                label={"Employee Name"}
                value={value.employee_name}
              />
              <div className="mb-3">
                <label className="form-label" style={{ fontSize: "14px" }}>
                  Give Write Access?
                </label>
                <div className="d-flex w-100 align-items-center">
                  <input
                    type="radio"
                    checked={value["access"] === false}
                    id="no"
                    name="access"
                    style={{ width: "20px", height: "20px" }}
                    className={"me-2 border-0 outline-0"}
                    onChange={(e) => {
                      onChange(false, "access");
                    }}
                  />{" "}
                  <label htmlFor="no" className="me-3">
                    No
                  </label>
                  <input
                    type="radio"
                    checked={value["access"] === true}
                    id="yes"
                    name="access"
                    style={{ width: "20px", height: "20px" }}
                    className={"me-1"}
                    onChange={(e) => {
                      onChange(true, "access");
                    }}
                  />{" "}
                  <label htmlFor="yes" className="me-1">
                    Yes
                  </label>
                </div>
              </div>
              <div className="text-end mt-4">
                <button
                  type="submit"
                  className={
                    "btn text-white fw-bold btn-sm " +
                    (isAdd ? " btn-success " : " btn-primary ")
                  }
                  style={{}}
                >
                  {isAdd ? "Create" : "Edit"} User
                </button>
              </div>
            </form>
          </div>
        </>
      }
    />
  );
}

export default AccountCreateFormModal;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../components/api";
import { getTable, updateTable } from "../urls";
import "bootstrap/dist/css/bootstrap.min.css";

import Table from "../components/Table";
import SelectInput from "../components/SelectInput";
import BottomBar from "../components/BottomBar";
import { MainTableData } from "../data/MainTableData";

function App({ table_key, userAccess }) {
  const [tableData, setTableData] = useState(); //memoised this
  const [originaltableData, setOriginalTableData] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); //remove this
  const [changes, setChanges] = useState({});
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    API({
      ...getTable,
      params: {
        table_key: table_key,
      },
      onSuccess: (res) => {
        let data = res.data.data;

        let mergedArray = [];
        for (let i = 0; i < data.length; i++) {
          mergedArray.push(
            Object.assign(
              {},
              data[i],
              MainTableData[table_key]["StaticData"][i]
            )
          );
        }
        resetChanges();
        setTableData(mergedArray);
        setOriginalTableData(mergedArray);
      },
      onError: (error) => {
        console.log(error);
      },
    });
    console.log(
      userAccess === "Write Access" ? false : true,
      userAccess,
      "TABLE PAGE RENDERED"
    );
    // Wait for userAccess to be available before rendering the component
    if (userAccess !== null) {
      setIsLoading(false);
    }
  }, [location, table_key, userAccess]);

  const updateTableData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    const oldData = tableData;
    const newData = oldData.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...oldData[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    });

    const hasUnsavedChanges =
      JSON.stringify(newData) !== JSON.stringify(originaltableData);
    setHasUnsavedChanges(hasUnsavedChanges);
    setTableData(newData);
    setChanges({
      ...changes,
      [rowIndex + 1]: {
        ...changes[rowIndex + 1],
        id: rowIndex + 1,
        [columnId]: value,
      },
    });
  };

  const saveChanges = () => {
    API({
      ...updateTable,
      body: { table_key: table_key, data: changes },
      onSuccess: (res) => {
        window.location.reload(false);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const resetChanges = () => {
    // console.log(originaltableData, "reseting", tableData);
    setTableData(originaltableData);
    setChanges({});
    setHasUnsavedChanges(false);
  };

  const defaultColumn = React.useMemo(
    () => ({
      Cell: ({
        value: initialValue,
        row: { index },
        column: { id },
        updateTableData, // This is a custom function that we supplied to our table instance
      }) => {
        // We need to keep and update the state of the cell normally
        const [value, setValue] = useState(initialValue);
        const onChange = (e) => {
          let value = e.target.value;
          if (typeof value === "string" && !isNaN(value) && value !== "") {
            value = parseInt(value);
          }
          setValue(value);
        };

        // We'll only update the external data when the input is blurred
        const onBlur = () => {
          updateTableData(index, id, value);
        };

        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        // Check to make sure not all columns are editable
        let columnId = MainTableData[table_key]["EditableTC"].findIndex(
          (column) => {
            return column.id === id;
          }
        );
        if (columnId > -1) {
          switch (MainTableData[table_key]["EditableTC"][columnId]["type"]) {
            case "select":
              if (id.includes("selective_category")) {
                return (
                  <SelectInput
                    disabled={
                      userAccess === "Write Access" ||
                      userAccess === "Admin Access"
                        ? false
                        : true
                    }
                    id={id}
                    label={"Select an Option"}
                    value={value ? value : ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={
                      MainTableData[table_key]["EditableTC"][0]["options"]
                    }
                  ></SelectInput>
                );
              }
              return (
                <SelectInput
                  disabled={
                    userAccess === "Write Access" ||
                    userAccess === "Admin Access"
                      ? false
                      : true
                  }
                  id={id}
                  label={"Select an Option"}
                  value={value ? value : ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={
                    MainTableData[table_key]["EditableTC"][columnId]["options"]
                  }
                ></SelectInput>
              );
            default:
              return (
                <input
                  disabled={
                    userAccess === "Write Access" ||
                    userAccess === "Admin Access"
                      ? false
                      : true
                  }
                  type={
                    MainTableData[table_key]["EditableTC"][columnId]["type"]
                  }
                  value={value ? value : ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  min={
                    MainTableData[table_key]["EditableTC"][columnId]["type"] ===
                    "number"
                      ? 0
                      : ""
                  }
                  className="p-1 w-75"
                  style={{ borderRadius: "4px", border: "1px solid #a5b0cb" }}
                />
              );
          }
        }
        return value;
      },
    }),
    [location, table_key, userAccess]
  );
  // const defaultColumn = {
  //   Cell: EditableCell,
  // };

  const columns = React.useMemo(
    () => MainTableData[table_key]["TableColumns"],
    [table_key, userAccess]
  );
  const filterOptions = React.useMemo(
    () => MainTableData[table_key]["Filters"],
    [table_key, userAccess]
  );
  const tableHeadingStyles = React.useMemo(
    () => MainTableData[table_key]["Styles"],
    [table_key, userAccess]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="table w-100 pt-4 pb-1 mb-0"
      style={{ backgroundColor: "#f5f8fd" }}
    >
      {tableData && (
        <>
          <Table
            columns={columns}
            data={tableData}
            filterOptions={filterOptions}
            tableHeadingStyles={tableHeadingStyles}
            updateTableData={updateTableData}
            defaultColumn={defaultColumn}
            skipPageReset={skipPageReset}
          />
          <BottomBar
            isDisabled={!hasUnsavedChanges}
            saveChanges={saveChanges}
            resetChanges={resetChanges}
          />
        </>
      )}
    </div>
  );
}

export default App;

import React, { useState, useEffect, createContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Protected from "./Protected";
import App from "../pages/TablePage";
import Page from "../layout/Page";
import LoginPage from "../pages/LoginPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import UserManagement from "../pages/UserManagement";
// import CBOutputPage from "../pages/CBOutputPage";
import CBOutputPage from "../pages/CBOutputPage2";
import CBListPage from "../pages/CBListPage";
import XMLFormPage from "../pages/XMLFormPage";
import Access from "./Access";

export const AppContext = createContext();

function AppRoutes(props) {
  const [user, setUser] = useState(
    localStorage.getItem("userId") ? localStorage.getItem("userId") : false
  );
  const [userAccess, setUserAccess] = useState(null);

  // States for CB Engine Results(List) page
  const [filtersState, setFiltersState] = useState(
    window.localStorage.getItem("filtersState")
      ? JSON.parse(window.localStorage.getItem("filtersState"))
      : {}
  );
  const [tableData, setTableData] = useState(
    window.localStorage.getItem("tableData")
      ? JSON.parse(window.localStorage.getItem("tableData"))
      : []
  );
  const [branchData, setBranchData] = useState([]);

  function updateUser(newValue, key) {
    let tempVal = { ...user };
    tempVal[key] = newValue;
    setUser(tempVal);
  }

  useEffect(() => {
    // localStorage.clear();
    setFiltersState(JSON.parse(window.localStorage.getItem("filtersState")));
    setTableData(JSON.parse(window.localStorage.getItem("tableData")));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("filtersState", JSON.stringify(filtersState));
    window.localStorage.setItem("tableData", JSON.stringify(tableData));
  }, [filtersState, tableData]);

  return (
    // <React.Suspense fallback={null}>
    <AppContext.Provider
      value={{
        filtersState,
        setFiltersState,
        tableData,
        setTableData,
        branchData,
        setBranchData,
      }}
    >
      <Router>
        <Routes>
          <Route
            element={
              <Page
                user={user}
                updateUser={updateUser}
                userAccess={userAccess}
              />
            }
          >
            <Route
              path="/"
              element={
                <Protected
                  user={user}
                  updateUser={updateUser}
                  setUserAccess={setUserAccess}
                />
              }
            >
              <Route
                path="/"
                element={
                  <Access access={"Admin Access"} userAccess={userAccess} />
                }
              >
                <Route path="xml/" element={<XMLFormPage />} />
                <Route
                  path="admin/"
                  element={
                    <UserManagement userAccess={userAccess}></UserManagement>
                  }
                />
                <Route
                  path="accountTypeExclusion/"
                  element={
                    <App
                      table_key={"AccountTypeExclusion"}
                      userAccess={userAccess}
                    ></App>
                  }
                />
                <Route
                  path="loanPack/"
                  element={
                    <App table_key={"LoanPack"} userAccess={userAccess}></App>
                  }
                />
                <Route
                  path="newClient/"
                  element={
                    <App table_key={"NewClient"} userAccess={userAccess}></App>
                  }
                />
                <Route
                  path="repeatClient/"
                  element={
                    <App
                      table_key={"RepeatClient"}
                      userAccess={userAccess}
                    ></App>
                  }
                />
                <Route
                  path="employee/"
                  element={
                    <App
                      table_key={"EmployeeCB"}
                      userAccess={userAccess}
                    ></App>
                  }
                />
              </Route>

              <Route index element={<CBListPage></CBListPage>} />
              <Route
                path="portfolio/"
                element={<CBOutputPage userAccess={userAccess}></CBOutputPage>}
              />
            </Route>
            <Route
              path="login/"
              element={<LoginPage updateUser={updateUser}></LoginPage>}
            />
            <Route
              path="reset/"
              element={<ResetPasswordPage></ResetPasswordPage>}
            />
          </Route>

          <Route path="*" element={<h1>/404</h1>} />
        </Routes>
      </Router>
    </AppContext.Provider>

    // {/* </React.Suspense> */}
  );
}

export default AppRoutes;

export const LoanPackStyles = (column) => {
  return {
    textAlign: ["Equifax Result"].includes(column.Header) ? "center" : "",
    borderWidth: "1px",
    backgroundColor: ["Equifax Result"].includes(column.Header)
      ? "#262626"
      : [
          "Sr No.",
          "Loan Amount",
          "Tenure",
          "Installment Amount",
          "Maximum Loan Cycle",
        ].includes(column.Header)
      ? "#7f7f7f"
      : "#f2f2f2",
    color: [
      "Equifax Result",
      "Sr No.",
      "Loan Amount",
      "Tenure",
      "Installment Amount",
      "Maximum Loan Cycle",
    ].includes(column.Header)
      ? "white"
      : "",
  };
};

import {
  NewClientTableColumns,
  NewClientEditableTC,
  NewClientFilters,
  NewClientSelectableTC,
  NewClientStaticData,
  NewClientStyles,
} from "./NewClient/index";
import {
  RepeatClientTableColumns,
  RepeatClientEditableTC,
  RepeatClientFilters,
  RepeatClientSelectableTC,
  RepeatClientStaticData,
  RepeatClientStyles,
} from "./RepeatClient/index";
import {
  LoanPackTableColumns,
  LoanPackEditableTC,
  LoanPackStyles,
} from "./LoanPack/index";
import {
  AccountTypeExclusionTableColumns,
  AccountTypeExclusionStyles,
  AccountTypeExclusionEditableTC,
} from "./AccountTypeExclusion/index";

export const MainTableData = {
  NewClient: {
    TableColumns: NewClientTableColumns,
    EditableTC: NewClientEditableTC,
    Filters: NewClientFilters,
    SelectableTC: NewClientSelectableTC,
    StaticData: NewClientStaticData,
    Styles: NewClientStyles,
  },
  RepeatClient: {
    TableColumns: RepeatClientTableColumns,
    EditableTC: RepeatClientEditableTC,
    Filters: RepeatClientFilters,
    SelectableTC: RepeatClientSelectableTC,
    StaticData: RepeatClientStaticData,
    Styles: RepeatClientStyles,
  },
  LoanPack: {
    TableColumns: LoanPackTableColumns,
    EditableTC: LoanPackEditableTC,
    Filters: [],
    SelectableTC: [],
    StaticData: [],
    Styles: LoanPackStyles,
  },
  AccountTypeExclusion: {
    TableColumns: AccountTypeExclusionTableColumns,
    EditableTC: AccountTypeExclusionEditableTC,
    Filters: [],
    SelectableTC: [],
    StaticData: [],
    Styles: AccountTypeExclusionStyles,
  },
  EmployeeCB: {
    TableColumns: NewClientTableColumns,
    EditableTC: NewClientEditableTC,
    Filters: NewClientFilters,
    SelectableTC: NewClientSelectableTC,
    StaticData: NewClientStaticData,
    Styles: NewClientStyles,
  },
};

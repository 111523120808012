import React from "react";

const SelectInput = ({
  options,
  onChange,
  onBlur,
  value,
  id,
  name,
  showLabel,
  label,
  wrapperClassName,
  className,
  style,
  disabled,
}) => {
  return (
    <div className={"select " + wrapperClassName}>
      <div className="data_content" style={{ lineHeight: "20px" }}>
        {showLabel ? label : ""}
      </div>
      <select
        style={{ ...style, color: value === "" ? "grey" : "black" }}
        className={className}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      >
        <option value={""} disabled={true} className="" style={{}}>
          {label}
        </option>
        {options &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SelectInput;

import React, { useEffect, useState, useMemo } from "react";
import { useGlobalFilter, useTable, useFilters } from "react-table";
import BTable from "react-bootstrap/Table";
import API from "../components/api";
import { getEmployeeData } from "../urls";
import SearchFilter from "../components/SearchFilter";
import AccountCreateFormModal from "../components/AccountCreateFormModal";
import { Modal } from "bootstrap";

const UserManagement = () => {
  const [tableData, setTableData] = useState([]); //memoised this
  const [headerHeight, setHeaderHeight] = useState(0);
  const [filterHeight, setFilterHeight] = useState(0);

  useEffect(() => {
    getData();
    setHeaderHeight(document.querySelector("#header").offsetHeight);
    // setFilterHeight(document.querySelector("#filter").offsetHeight);
  }, []);

  //   think of passing location and table_key into useEffect when built succesfully for single page.
  function getData() {
    API({
      ...getEmployeeData,
      onSuccess: (res) => {
        let data = res.data.data;
        setTableData(data);
      },
      onError: (error) => {
        alert(error);
      },
    });
  }

  function openModal(isAdd, data) {
    let elem = document.getElementById("add_user_modal");
    elem.setAttribute("data-isAdd", JSON.stringify(isAdd));
    elem.setAttribute("data-selectedData", JSON.stringify(data));
    Modal.getOrCreateInstance(elem).show();
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "employee_id",
      },
      {
        Header: "Name",
        accessor: "employee_name",
      },
      {
        Header: "Department Name",
        accessor: "department_name",
      },
      {
        Header: "Designation Type",
        accessor: "designation_type",
      },
      {
        Header: "Designation Name",
        accessor: "designation_name",
      },
      {
        Header: "Date of Joining",
        accessor: "date_of_joining",
      },
      {
        Header: "Create",
        Cell: ({ row }) => {
          let is_user = row.original.is_user;
          return (
            <button
              className={
                "btn text-white fw-bold btn-sm " +
                (is_user ? " btn-primary" : " btn-success ")
              }
              style={{ minWidth: "100px" }}
              onClick={() => {
                let employee_id = row.values.employee_id;
                let employee_name = row.values.employee_name;
                let employee_access = row.original.access;
                openModal(!is_user, {
                  employee_id: employee_id,
                  employee_name: employee_name,
                  access: is_user
                    ? employee_access === "Write Access"
                      ? true
                      : employee_access === "Read Access"
                      ? false
                      : false
                    : undefined,
                });
              }}
            >
              {is_user ? "Edit" : "Create"}
            </button>
          );
        },
      },
    ],
    []
  );
  const memoTableData = useMemo(() => [...tableData], [tableData]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: memoTableData,
    },
    useGlobalFilter
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;
  const { globalFilter } = state;
  return (
    <>
      <div className="table w-100 pt-4 pb-1 mb-0">
        <div className="px-4">
          <div id={"filter"} className="sticky-top">
            <SearchFilter
              placeholder={"Employee Name"}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            ></SearchFilter>
          </div>
          <div
            id={"table"}
            className="overflow-scroll"
            style={{
              height: `calc(100vh - ${headerHeight}px - ${filterHeight}px - 28px - 60px )`,
              fontSize: "10px",
            }}
          >
            <BTable {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th
                        className="py-2 px-2"
                        style={{
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontSize: "14px",
                          fontWeight: "700",
                          fontStyle: "normal",
                          lineHeight: "24px",
                          border: "0px solid black",
                          verticalAlign: "middle",
                          width: "100px",
                        }}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                style={{ fontSize: "12.5px", lineHeight: "16px" }}
              >
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{
                        backgroundColor: i % 2 === 0 ? "#ffffff" : "#f5f9ff",
                      }}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px 10px 16px 10px",
                            color: "#122248",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </BTable>
            <AccountCreateFormModal></AccountCreateFormModal>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;

import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  useGlobalFilter,
  useTable,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import BTable from "react-bootstrap/Table";
import API from "../components/api";
import { getBranch, getBranchData, getEngineOutput } from "../urls";
import { Link } from "react-router-dom";
import SearchFilter from "../components/SearchFilter";
import SelectInput from "../components/SelectInput";
import { eye, filter, reset, sort, sortDown, sortUp } from "../images";
import { ExportToExcel } from "../components/ExportToExcel";
import DateInput from "../components/DateInput";
import { AppContext } from "../router/router";

function getFilterValue(filters, id) {
  let obj = filters.find((obj) => obj.id === id);
  if (obj) {
    return obj.value;
  } else {
    return "";
  }
}

function SearchBox({
  label,
  wrapperClassName,
  // filterValue,
  // setFilter,
  value,
  onChange,
  placeholder,
  id,
}) {
  if (!wrapperClassName || wrapperClassName === " ") {
    wrapperClassName = " align-items-center gap-3 ";
  }
  return (
    // align-items-center gap-3 add this into the wrapper class
    <div className={"px-0 d-flex  " + wrapperClassName}>
      <div className="data_content" style={{ lineHeight: "20px" }}>
        {label && label}
      </div>
      <input
        className="p-2"
        style={{ fontSize: "15px", width: "15ch" }}
        // value={filterValue || ""}
        // onChange={(e) => setFilter(id, e.target.value)}
        value={id in value ? value[id] : ""}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            // Handle Enter key press logic here
          }
        }}
      ></input>
    </div>
  );
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
  if (ed || sd) {
    return rows.filter((r) => {
      // format data
      var dateAndHour = r.values[id].split(" ");
      var [day, month, year] = dateAndHour[0].split("-");
      var date = [month, day, year].join("/");
      var hour = dateAndHour[1];
      var formattedData = date + " " + hour;
      const cellDate = new Date(formattedData);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}

const CBListPage = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [filterHeight, setFilterHeight] = useState(0);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

  const {
    filtersState,
    setFiltersState,
    tableData,
    setTableData,
    branchData,
    setBranchData,
  } = useContext(AppContext);

  useEffect(() => {
    if (branchData.length === 0) {
      API({
        ...getBranchData,
        onSuccess: (res) => {
          let branch_data = res.data.data;
          setBranchData(branch_data);
        },
        onError: (error) => {
          alert(error);
        },
      });
    }
    // setHeaderHeight(document.querySelector("#header").offsetHeight);
    // setFilterHeight(document.querySelector("#filter").offsetHeight);
  }, []);

  function getData() {
    API({
      ...getEngineOutput,
      params: {
        reference: filtersState.reference,
        group_name: filtersState.group_name,
        branch: filtersState.branch,
        recommendation: filtersState.recommendation,
        final_recommendation: filtersState.final_recommendation,
        from_date: filtersState.from_date,
        to_date: filtersState.to_date,
        offset: pagination.offset,
        limit: pagination.limit * 3,
      },
      onSuccess: (res) => {
        let engine_list_data = res.data.data.engine_list_data;
        if (engine_list_data.length !== 0) {
          setTableData(engine_list_data);
        } else {
          alert("No data available.");
        }
      },
      onError: (error) => {
        alert(error);
      },
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "Branch",
        accessor: "branch",
        // Filter: SearchBox,
      },
      {
        Header: "Creation Date",
        accessor: "creation_date",
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Date Of Issue",
        accessor: "date_of_issue",
      },
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Group Name",
        accessor: "group_name",
      },
      {
        Header: "Recommendation",
        accessor: "recommendation",
        Cell: ({ row }) => {
          let value =
            typeof row.values.recommendation === "string"
              ? row.values.recommendation.toLowerCase()
              : row.values.recommendation;
          return (
            <button
              className={
                "btn btn-sm disabled " +
                (value === "approve" || value === "approved"
                  ? " btn-success text-white "
                  : value === "reject" || value === "rejected"
                  ? " btn-danger text-white "
                  : value === "conditionally approve" ||
                    value === "conditionally approved"
                  ? " btn-primary text-white "
                  : value === "refer" || value === "referred"
                  ? " btn-warning text-dark "
                  : " btn-secondary text-white ")
              }
            >
              {row.values.recommendation}
            </button>
          );
        },
      },
      {
        Header: "Final Recommendation",
        accessor: "final_recommendation",
        Cell: ({ row }) => {
          let value =
            typeof row.values.final_recommendation === "string"
              ? row.values.final_recommendation.toLowerCase()
              : row.values.final_recommendation;
          return (
            <button
              className={
                "btn btn-sm disabled " +
                (value === "approve" || value === "approved"
                  ? " btn-success text-white "
                  : value === "reject" || value === "rejected"
                  ? " btn-danger text-white "
                  : value === "conditionally approve" ||
                    value === "conditionally approved"
                  ? " btn-primary text-white "
                  : value === "refer" || value === "referred"
                  ? " btn-warning text-dark "
                  : " btn-secondary text-white ")
              }
            >
              {row.values.final_recommendation}
            </button>
          );
        },
      },
      {
        Header: "VIEW",
        Cell: ({ row }) => {
          return (
            <Link
              to={`portfolio`}
              state={{
                creation_date: row.values.creation_date,
                reference: row.values.reference,
              }}
            >
              <img src={eye}></img>
              {/* <button
                className={"btn text-white fw-bold btn-sm " + " btn-primary"}
                style={{ minWidth: "100px" }}
              >
                {"VIEW"}
              </button> */}
            </Link>
          );
        },
      },
    ],
    []
  );

  const memoTableData = useMemo(() => [...tableData], [tableData]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: memoTableData,
      autoResetPage: true,
      // autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setFilter,
    setGlobalFilter,
    setAllFilters,
    setSortBy,
    state,
  } = tableInstance;
  const { filters, globalFilter, pageIndex, pageSize } = state;
  return (
    <>
      <div
        className=" w-100 mb-0 px-4 d-flex flex-column flex-grow-1 h-50"
        style={{ backgroundColor: "#f5f8fd" }}
      >
        <div className="my-2" style={{ fontWeight: "600", fontSize: "22px" }}>
          Credit Bureau List (Latest)
        </div>
        <div id={"filter"} className="sticky-top my-1">
          <form
            className="px-0"
            onSubmit={(e) => {
              e.preventDefault();
              if (Object.keys(filtersState).length !== 0) {
                getData();
              } else {
                alert("Please apply some filters.");
              }
            }}
          >
            <div className="d-flex flex-wrap align-items-end gap-2 px-0">
              {/* <img
                  src={filter}
                  style={{ height: "45px", fill: "#64748b" }}
                  alt={"filter"}
                ></img> */}

              <SearchBox
                label={"Reference"}
                wrapperClassName={" flex-column gap-0 align-items-start "}
                // filterValue={getFilterValue(filters, "reference")}
                // setFilter={setFilter}
                value={filtersState}
                onChange={(e) => {
                  setFiltersState((prev) => ({
                    ...prev,
                    reference: e.target.value,
                  }));
                }}
                placeholder={"Reference"}
                id={"reference"}
              ></SearchBox>
              <SearchBox
                label={"Group Name"}
                wrapperClassName={" flex-column gap-0 align-items-start "}
                value={filtersState}
                onChange={(e) => {
                  setFiltersState((prev) => ({
                    ...prev,
                    group_name: e.target.value,
                  }));
                }}
                placeholder={"Group Name"}
                id={"group_name"}
              ></SearchBox>
              <SelectInput
                showLabel={true}
                label={"Branch"}
                // value={getFilterValue(filters, "branch")}
                value={"branch" in filtersState ? filtersState["branch"] : ""}
                wrapperClassName={" "}
                className={"p-2 ps-1"}
                style={{ fontSize: "15px", height: "auto", minWidth: "19ch" }}
                options={
                  branchData
                    ? branchData.map((branch) => {
                        return { label: branch.branch, value: branch.branch };
                      })
                    : []
                }
                onChange={(e) => {
                  // setFilter("branch", e.target.value);
                  // UpdateFilterState("branch", e.target.value);
                  setFiltersState((prev) => ({
                    ...prev,
                    branch: e.target.value,
                  }));
                }}
                // id={"branch"}
              />
              <SelectInput
                showLabel={true}
                label={"Recommendation"}
                // value={getFilterValue(filters, "recommendation")}
                value={
                  "recommendation" in filtersState
                    ? filtersState["recommendation"]
                    : ""
                }
                wrapperClassName={" "}
                className={"p-2 ps-1"}
                style={{ fontSize: "15px", height: "auto", minWidth: "19ch" }}
                options={[
                  { label: "None", value: "" },
                  { label: "Referred", value: "Referred" },
                  { label: "Rejected", value: "Rejected" },
                  { label: "Approved", value: "Approved" },
                ]}
                onChange={(e) => {
                  // setFilter("recommendation", e.target.value);
                  // UpdateFilterState("recommendation", e.target.value);
                  setFiltersState((prev) => ({
                    ...prev,
                    recommendation: e.target.value,
                  }));
                }}
              />
              <SelectInput
                showLabel={true}
                label={"Final Recommendation"}
                // value={getFilterValue(filters, "final_recommendation")}
                value={
                  "final_recommendation" in filtersState
                    ? filtersState["final_recommendation"]
                    : ""
                }
                wrapperClassName={" "}
                className={"p-2 ps-1"}
                style={{ fontSize: "15px", height: "auto", minWidth: "23ch" }}
                options={[
                  { label: "None", value: "" },
                  { label: "Referred", value: "Referred" },
                  { label: "Rejected", value: "Rejected" },
                  { label: "Approved", value: "Approved" },
                ]}
                onChange={(e) => {
                  // setFilter("final_recommendation", e.target.value);
                  // UpdateFilterState("final_recommendation", e.target.value);
                  setFiltersState((prev) => ({
                    ...prev,
                    final_recommendation: e.target.value,
                  }));
                }}
              />
              <DateInput
                // value={getFilterValue(filters, "creation_date")[0]}
                value={
                  "from_date" in filtersState ? filtersState["from_date"] : ""
                }
                label={"From Date"}
                onChange={(e) => {
                  // let newFilterValue = [
                  //   e.target.value,
                  //   getFilterValue(filters, "creation_date")
                  //     ? getFilterValue(filters, "creation_date")[1]
                  //     : "",
                  // ];
                  // setFilter("creation_date", newFilterValue);

                  setFiltersState((prev) => ({
                    ...prev,
                    from_date: e.target.value,
                  }));
                }}
                required={
                  "to_date" in filtersState
                    ? filtersState["to_date"]
                      ? true
                      : false
                    : false
                }
              ></DateInput>
              <DateInput
                // value={getFilterValue(filters, "creation_date")[1]}
                value={"to_date" in filtersState ? filtersState["to_date"] : ""}
                label={"To Date"}
                onChange={(e) => {
                  // let newFilterValue = [
                  //   getFilterValue(filters, "creation_date")
                  //     ? getFilterValue(filters, "creation_date")[0]
                  //     : "",
                  //   e.target.value,
                  // ];
                  // setFilter("creation_date", newFilterValue);

                  setFiltersState((prev) => ({
                    ...prev,
                    to_date: e.target.value,
                  }));
                }}
                required={
                  "from_date" in filtersState
                    ? filtersState["from_date"]
                      ? true
                      : false
                    : false
                }
                min={
                  "from_date" in filtersState ? filtersState["from_date"] : ""
                }
              ></DateInput>
              <button
                // disabled={isDisabled}
                type="submit"
                className="px-3 py-2 fw-semibold h-100 w-auto"
                style={{
                  backgroundColor: "#000000",
                  color: "white",
                  borderRadius: "4px",
                  transition: "0.3s",
                  cursor: "pointer",
                }}
              >
                Apply Filters
              </button>
              <ExportToExcel
                apiData={tableData}
                fileName={"CB_List_Excel"}
                style={{ height: "38px" }}
              />
              <img
                src={reset}
                style={{ height: "38px", fill: "#64748b", cursor: "pointer" }}
                alt={"reset"}
                onClick={() => {
                  setAllFilters([]);
                  setFiltersState({});
                  setTableData([]);
                  setSortBy([]);
                }}
              ></img>
            </div>
          </form>
        </div>
        <div
          id={"table"}
          className="overflow-scroll border my-1 flex-grow-1"
          style={{
            // height: `calc(100vh - ${headerHeight}px - ${filterHeight}px - 28px - 60px - 105px)`,
            fontSize: "10px",
            borderColor: "#dee2e6",
          }}
        >
          <BTable {...getTableProps()} className="overflow-scroll">
            <thead
              style={{
                position: "sticky",
                top: "0",
                boxShadow: "rgba(21, 92, 171, 0.1) 2px 3px 5px",
                zIndex: "1",
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <th
                      className="py-2 px-2"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                        fontStyle: "normal",
                        lineHeight: "24px",
                        border: "0px solid black",
                        verticalAlign: "middle",
                        // width: "100px",
                        backgroundColor: "#e1efff",
                        color: "black",
                        width: "auto",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          cursor: ["VIEW"].includes(column.Header)
                            ? "auto"
                            : "pointer",
                        }}
                      >
                        <div className="me-auto">{column.render("Header")}</div>
                        <div
                          style={{
                            display: ["VIEW"].includes(column.Header)
                              ? "none"
                              : "block",
                          }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <img src={sortDown}></img>
                            ) : (
                              <img src={sortUp}></img>
                            )
                          ) : (
                            <img src={sort}></img>
                          )}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{ fontSize: "12.5px", lineHeight: "16px" }}
            >
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{
                      backgroundColor: i % 2 === 0 ? "#ffffff" : "#f5f9ff",
                    }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px 10px 16px 10px",
                          color: "#122248",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </BTable>
        </div>
        <div className=" my-1 mb-2">
          <div className="d-flex align-items-center gap-2 px-0">
            <select
              value={pageSize}
              onChange={(e) => {
                // setPagination((prev) => ({
                //   ...prev,
                //   limit: Number(e.target.value),
                // }));
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <button
              disabled={!canPreviousPage}
              className="btn text-white fw-bold btn-sm  btn-dark "
              onClick={() => gotoPage(0)}
            >
              {"<<"}
            </button>
            <button
              disabled={!canPreviousPage}
              // disabled={pagination.offset > 0 ? false : true}
              className="btn text-white fw-bold btn-sm  btn-dark "
              onClick={() => {
                // if (pagination.offset > 0) {
                //   setPagination((prev) => ({
                //     ...prev,
                //     offset: prev.offset - prev.limit,
                //   }));
                // }
                previousPage();
              }}
            >
              {"< Previous"}
            </button>

            <span>
              Page {pageIndex + 1} / {pageOptions.length}
            </span>

            <span>
              | Goto Page{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value - 1)
                    : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "50px" }}
              ></input>
            </span>

            <button
              // disabled={!canNextPage}
              className="btn text-white fw-bold btn-sm  btn-dark "
              onClick={() => {
                if (pageOptions.length - pageIndex - 1 < 4) {
                  setPagination((prev) => ({
                    ...prev,
                    offset: prev.offset + prev.limit * 3,
                  }));
                }
                nextPage();
              }}
            >
              {"Next >"}
            </button>
            <button
              disabled={!canNextPage}
              className="btn text-white fw-bold btn-sm  btn-dark "
              onClick={() => gotoPage(pageCount - 1)}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CBListPage;

export const NewClientFilters = [
  {
    label: "All Filters",
    value: "",
  },
  {
    label: "General",
    value: "General",
  },
  {
    label: "MFI Track Record",
    value: "MFI Track Record",
  },
  {
    label: "Retail Unsecured Track Record",
    value: "Retail Unsecured Track Record",
  },
  {
    label: "Retail Secured Track Record",
    value: "Retail Secured Track Record",
  },
  {
    label: "FOIR",
    value: "FOIR",
  },
];

export const getTable = {
  url: "/website/table/",
  method: "GET",
};

export const updateTable = {
  url: "/website/table/",
  method: "PUT",
};
export const getToken = {
  url: "/website/token/",
  method: "POST",
};

// export const createUser = {
//   url: "/website/token/",
//   method: "POST",
// };

export const getEmployeeData = {
  url: "/website/userManagement/",
  method: "GET",
};

export const createUser = {
  url: "/website/userManagement/",
  method: "POST",
};

export const updateUser = {
  url: "/website/userManagement/",
  method: "PUT",
};

export const getEmployeeUserAccess = {
  url: "/website/hasWriteAccess/",
  method: "GET",
};

export const getEngineOutput = {
  url: "/website/engineView/",
  method: "GET",
};

export const uploadFile = {
  url: "/website/xml/",
  method: "POST",
};

export const cbEngine = {
  url: "/website/cb_engine/",
  method: "POST",
};

export const postEngineOutput = {
  url: "/website/engineView/",
  method: "POST",
};

export const updateEngineOutput = {
  url: "/website/engineView/",
  method: "PUT",
};

export const recalculateCbEngine = {
  url: "/website/cb_engine/",
  method: "PUT",
};

export const testAPI = {
  url: "/website/testAPI/",
  method: "POST",
};

export const login = {
  url: "/website/login/",
  method: "POST",
};

export const resetPassword = {
  url: "/website/resetPassword/",
  method: "POST",
};

export const getBranchData = {
  url: "/website/branch/",
  method: "GET",
};

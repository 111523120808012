import axios from "axios";

export default function API({
  url,
  method,
  params = {},
  body = {},
  isFormData = false,
  uploadProgress = () => {},
  onError = (error, response) => {},
  onSuccess,
}) {
  // const protocol = window.location.protocol;

  const data = () => {
    if (method !== "GET") {
      if (isFormData) return body;
      else return JSON.stringify(body);
    } else return null;
  };

  const progress = (progressPercent) => {
    if (isFormData)
      return uploadProgress ? uploadProgress(progressPercent) : null;
  };
  const fixedHeaders = {
    Authorization: localStorage.getItem("accessToken")
      ? `Bearer ${localStorage.getItem("accessToken")}`
      : "",
  };
  const development =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  axios({
    // baseURL: development
    //   ? "http://localhost:8000/api/"
    //   : `${window.location.origin}/api/`,
    baseURL: `${window.location.origin}/api/`,
    // baseURL: `http://127.0.0.1:8000/api/`,
    url: url,
    method: method,
    params: params,
    data: data(body),
    onUploadProgress: (progressEvent) =>
      progress(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
    headers: isFormData
      ? { ...fixedHeaders, "Content-Type": "multipart/form-data" }
      : { ...fixedHeaders, "Content-Type": "application/json" },
    timeout: 0,
  })
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
      if (error?.response?.status === 401) {
        window.location.href = "/login";
        localStorage.clear();
      }
    });
}

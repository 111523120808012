import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import API from "../components/api";
import { login } from "../urls";

const LoginPage = ({ updateUser }) => {
  const [formData, setFormData] = useState();
  const navigate = useNavigate();

  function onChange(newValue, id) {
    let tempVal = { ...formData };
    tempVal[id] = newValue;
    setFormData(tempVal);
  }
  function onSubmit(e) {
    e.preventDefault();
    let submitFormData = new FormData();

    submitFormData.append("password", formData.password);
    submitFormData.append("username", formData.username);
    API({
      ...login,
      body: submitFormData,
      isFormData: true,
      onSuccess: (res) => {
        console.log(res);
        const password_reset_required = res.data.data.password_reset_required;
        alert(res.data.message);
        if (password_reset_required) {
          alert("Please Reset your Password.");
          localStorage.setItem("accessToken", res.data.data.access);
          navigate("/reset");
        } else {
          // Save the access token to local storage
          if (res.data.data.access) {
            localStorage.setItem("accessToken", res.data.data.access);

            // Decode the access token to get the user ID
            const decodedToken = jwt_decode(res.data.data.access);
            const userId = decodedToken.user_id;

            // Save the user ID to local storage
            localStorage.setItem("userId", userId);
            updateUser(userId, "userId");
            navigate("/");
          }
        }
      },
      onError: (error) => {
        alert(error);
      },
    });
  }
  const InputClassName = `w-100 my-2 fs-5 py-2 px-2`;
  const InputStyle = {
    flexBasis: "40%",
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="border mx-auto mt-5 p-5 col-lg-4 col-md-6 col-sm-6"
          style={{
            width: "30%",
            border: "3px solid rgb(158 173 186)",
            borderRadius: "26px",
            // boxShadow: "10px 10px 20px #bebebe,-10px -10px 20px #ffffff",
            boxShadow: "0 4px 35px rgba(0,0,0,.1)",
          }}
        >
          <form
            onSubmit={onSubmit}
            id="login"
            className="w-100 d-flex flex-column gap-3 align-items-start p-2"
          >
            <h3>Login Page</h3>
            <div className="w-100 fs-5 fw-semibold gap-4">
              <input
                className={InputClassName}
                style={InputStyle}
                id={"username"}
                value={formData ? formData["username"] : ""}
                onChange={(e) => {
                  onChange(e.target.value, e.target.id);
                }}
                placeholder="Enter Username"
                required={true}
              ></input>
            </div>
            <div className="w-100 fs-5 fw-semibold gap-4">
              <input
                type={"password"}
                className={InputClassName}
                style={InputStyle}
                id={"password"}
                value={formData ? formData["password"] : ""}
                onChange={(e) => {
                  onChange(e.target.value, e.target.id);
                }}
                placeholder={"Enter Password"}
                required={true}
              ></input>
            </div>

            <div className="w-100 gap-4">
              <button
                type="submit"
                className="w-100 fs-5 text-center p-2"
                style={{
                  color: "white",
                  backgroundColor: "#0a66c2",
                  border: "0",
                  borderRadius: "50px",
                  cursor: "pointer",
                  alignSelf: "end",
                  fontFamily: "Roboto",
                  fontWeight: "300",
                }}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

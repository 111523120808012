import React from "react";

const BottomBar = ({ isDisabled, saveChanges, resetChanges }) => {
  return (
    <div className="d-flex align-items-center gap-3 px-4 py-2" style={{ height: "90px", fontSize: "14px" }}>
      <button
        disabled={isDisabled}
        className="px-3 py-2 fw-semibold "
        style={{
          backgroundColor: isDisabled ? "#64748b" : "#f91616",
          color: "white",
          borderRadius: "4px",
          transition: "0.3s",
        }}
        onClick={resetChanges}
      >
        Reset
      </button>
      <button
        disabled={isDisabled}
        className="px-3 py-2 fw-semibold"
        style={{
          backgroundColor: isDisabled ? "#64748b" : "#f97316",
          color: "white",
          borderRadius: "4px",
          transition: "0.3s",
        }}
        onClick={saveChanges}
      >
        Save Changes
      </button>
    </div>
  );
};

export default BottomBar;

export const NewClientTableColumns=[
    {
      Header: "Rules",
      columns: [
        {
          Header: "Rules 2",
          columns: [
            {
              Header: "Category",
              accessor: "category",
            },
            {
              Header: "Condition",
              accessor: "condition",
            },
          ],
        },
      ],
    },
    {
      Header: "Approve",
      columns: [
        {
          Header: "Approve 2",
          columns: [
            {
              Header: "-",
              accessor: "approve_static",
            },
            {
              Header: "Manually Entered",
              accessor: "approve",
            },
          ],
        },
      ],
    },
    {
      Header: "Conditional Approval",
      columns: [
        {
          Header: "Approval Limit",
          columns: [
            {
              Header: "-",
              accessor: "conditional_approval_approval_limit_static",
            },
            {
              Header: "Manually Entered",
              accessor: "conditional_approval_approval_limit",
            },
          ],
        },
        {
          Header: "Condition 1",
          columns: [
            {
              Header: "Condition Title",
              accessor: "conditional_approval_condition_title1_static",
            },
          ],
        },
        {
          Header: "Value 1",
          columns: [
            {
              Header: "-",
              accessor: "conditional_approval_value1_static",
            },
            {
              Header: "Manually Entered",
              accessor: "conditional_approval_value1",
            },
          ],
        },
        {
          Header: "Condition 2",
          columns: [
            {
              Header: "Condition Title",
              accessor: "conditional_approval_condition_title2_static",
            },
          ],
        },
  
        {
          Header: "Value 2",
          columns: [
            {
              Header: "-",
              accessor: "conditional_approval_value2_static",
            },
            {
              Header: "Manually Entered",
              accessor: "conditional_approval_value2",
            },
          ],
        },
        {
          Header: "Parameter ",
          columns: [
            {
              Header: "Condition Title",
              accessor: "conditional_approval_parameter_condition_title_static",
            },
          ],
        },
      ],
    },
  
    {
      Header: "Refer",
      columns: [
        {
          Header: "Refer 2",
          columns: [
            {
              Header: "-",
              accessor: "refer_static",
            },
            {
              Header: "Manually Entered",
              accessor: "refer",
            },
          ],
        },
      ],
    },
    {
      Header: "Reject",
      columns: [
        {
          Header: "Reject 2",
          columns: [
            {
              Header: "-",
              accessor: "reject_static",
            },
            {
              Header: "Manually Entered",
              accessor: "reject",
            },
          ],
        },
      ],
    },
    {
      Header: "Assumptions",
      columns: [
        {
          Header: "Assumptions 2",
          columns: [
            {
              Header: "Default Date",
              accessor: "default_date",
            },
            {
              Header: "Write Off Date",
              accessor: "write_off_date",
            },
          ],
        },
      ],
    },
    {
      Header: "Specific Exclusion 1 (Full)",
      columns: [
        {
          Header: "#1",
          columns: [
            {
              Header: "#1 Condition",
              accessor: "specific_exclusion1_condition1",
            },
            {
              Header: "-",
              accessor: "specific_exclusion1_value1_static",
            },
  
            {
              Header: "#1 Value",
              accessor: "specific_exclusion1_value1",
            },
          ],
        },
        {
          Header: "#2",
          columns: [
            {
              Header: "#2 Condition",
              accessor: "specific_exclusion2_condition2",
            },
            {
              Header: "-",
              accessor: "specific_exclusion2_value2_static",
            },
            {
              Header: "#2 Value",
              accessor: "specific_exclusion2_value2",
            },
          ],
        },
      ],
    },
    {
      Header: "Specific Exclusion #2 (Full)",
      columns: [
        {
          Header: "#1A",
          columns: [
            {
              Header: "#1A Condition",
              accessor: "specific_exclusion_2_condition1a",
            },
            {
              Header: "-",
              accessor: "specific_exclusion_2_value1a_static",
            },
            {
              Header: "#1A Value",
              accessor: "specific_exclusion_2_value1a",
            },
          ],
        },
        {
          Header: "#2A",
          columns: [
            {
              Header: "#2A Condition",
              accessor: "specific_exclusion_2_condition2a",
            },
            {
              Header: "-",
              accessor: "specific_exclusion_2_value2a_static",
            },
            {
              Header: "#2A Value",
              accessor: "specific_exclusion_2_value2a",
            },
          ],
        },
        {
          Header: "#3A",
          columns: [
            {
              Header: "#3A Condition",
              accessor: "specific_exclusion_2_condition3a",
            },
            {
              Header: "-",
              accessor: "specific_exclusion_2_value3a_static",
            },
            {
              Header: "#3A Value",
              accessor: "specific_exclusion_2_value3a",
            },
            {
              Header: "Applicability",
              accessor: "specific_exclusion_2_applicability3a",
            },
          ],
        },
      ],
    },
    {
      Header: "Specific Exclusion #2 (Selective 1 and 2)",
      columns: [
        {
          Header: "Selective Category #1",
          columns: [
            {
              Header: "#1 Category",
              accessor: "selective_category_1_category",
            },
            {
              Header: "Parameter",
              accessor: "selective_category_1_parameter",
            },
            {
              Header: "#1 Value",
              accessor: "selective_category_1_value",
            },
          ],
        },
        {
          Header: "Selective Category #2",
          columns: [
            {
              Header: "#2 Category",
              accessor: "selective_category_2_category",
            },
            {
              Header: "Parameter",
              accessor: "selective_category_2_parameter",
            },
            {
              Header: "#2 Value",
              accessor: "selective_category_2_value",
            },
          ],
        },
      ],
    },
    {
      Header: "Specific Exclusion #2 (Selective 3 and 4)",
      columns: [
        {
          Header: "Selective Category #3",
          columns: [
            {
              Header: "#3 Category",
              accessor: "selective_category_3_category",
            },
            {
              Header: "Parameter",
              accessor: "selective_category_3_parameter",
            },
            {
              Header: "#3 Value",
              accessor: "selective_category_3_value",
            },
          ],
        },
        {
          Header: "Selective Category #4",
          columns: [
            {
              Header: "#4 Category",
              accessor: "selective_category_4_category",
            },
            {
              Header: "Parameter",
              accessor: "selective_category_4_parameter",
            },
            {
              Header: "#4 Value",
              accessor: "selective_category_4_value",
            },
          ],
        },
      ],
    },
    {
      Header: "Specific Exclusion #2 (Selective 5 and 6)",
      columns: [
        {
          Header: "Selective Category #5",
          columns: [
            {
              Header: "#5 Category",
              accessor: "selective_category_5_category",
            },
            {
              Header: "Parameter",
              accessor: "selective_category_5_parameter",
            },
            {
              Header: "#5 Value",
              accessor: "selective_category_5_value",
            },
          ],
        },
        {
          Header: "Selective Category #6",
          columns: [
            {
              Header: "#6 Category",
              accessor: "selective_category_6_category",
            },
            {
              Header: "Parameter",
              accessor: "selective_category_6_parameter",
            },
            {
              Header: "#6 Value",
              accessor: "selective_category_6_value",
            },
          ],
        },
      ],
    },
    {
      Header: "Specific Exclusion #2 (Selective 7)",
      columns: [
        {
          Header: "Selective Category #7",
          columns: [
            {
              Header: "#7 Category",
              accessor: "selective_category_7_category",
            },
            {
              Header: "Parameter",
              accessor: "selective_category_7_parameter",
            },
            {
              Header: "#7 Value",
              accessor: "selective_category_7_value",
            },
          ],
        },
      ],
    },
  ]

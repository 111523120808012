export const NewClientEditableTC = [
  {
    id: "selective_category",
    options: [
      {
        label: "Auto Loan",
        value: "Auto Loan",
      },
      {
        label: "Business Loan",
        value: "Business Loan",
      },
      {
        label: "Business Loan - Priority Sector - Agriculture",
        value: "Business Loan - Priority Sector - Agriculture",
      },
      {
        label: "Business Loan - Priority Sector - Others",
        value: "Business Loan - Priority Sector - Others",
      },
      {
        label: "Business Loan - Priority Sector - Small Business",
        value: "Business Loan - Priority Sector - Small Business",
      },
      {
        label: "Business Loan - Secured",
        value: "Business Loan - Secured",
      },
      {
        label: "Business Loan - Unsecured",
        value: "Business Loan - Unsecured",
      },
      {
        label: "Commercial Vehicle Loan",
        value: "Commercial Vehicle Loan",
      },
      {
        label: "Construction Equipment Loan",
        value: "Construction Equipment Loan",
      },
      {
        label: "Consumer Loan",
        value: "Consumer Loan",
      },
      {
        label: "Credit Card",
        value: "Credit Card",
      },
      {
        label: "Education Loan",
        value: "Education Loan",
      },
      {
        label: "GECL Loan Secured",
        value: "GECL Loan Secured",
      },
      {
        label: "Gold Loan",
        value: "Gold Loan",
      },
      {
        label: "Housing Loan",
        value: "Housing Loan",
      },
      {
        label: "Individual",
        value: "Individual",
      },
      {
        label: "JLG Group",
        value: "JLG Group",
      },
      {
        label: "JLG Individual",
        value: "JLG Individual",
      },
      {
        label: "Kisan Credit Card",
        value: "Kisan Credit Card",
      },
      {
        label: "Loan Against Bank Deposits",
        value: "Loan Against Bank Deposits",
      },
      {
        label: "MicroFinance Business Loan",
        value: "MicroFinance Business Loan",
      },
      {
        label: "MicroFinance Housing Loan",
        value: "MicroFinance Housing Loan",
      },
      {
        label: "MicroFinance Others",
        value: "MicroFinance Others",
      },
      {
        label: "MicroFinance Personal Loan",
        value: "MicroFinance Personal Loan",
      },
      {
        label: "Mudra Loans - Shishu / Kishor / Tarun",
        value: "Mudra Loans - Shishu / Kishor / Tarun",
      },
      {
        label: "Other",
        value: "Other",
      },
      {
        label: "Overdraft",
        value: "Overdraft",
      },
      {
        label: "Personal Loan",
        value: "Personal Loan",
      },
      {
        label: "Pradhan Mantri Awas Yojana",
        value: "Pradhan Mantri Awas Yojana",
      },
      {
        label: "Prime Minister Jaan Dhan Yojana - Overdraft",
        value: "Prime Minister Jaan Dhan Yojana - Overdraft",
      },
      {
        label: "Property Loan",
        value: "Property Loan",
      },
      {
        label: "Secured Credit Card",
        value: "Secured Credit Card",
      },
      {
        label: "SHG Group",
        value: "SHG Group",
      },
      {
        label: "SHG Individual",
        value: "SHG Individual",
      },
      {
        label: "Tractor Loan",
        value: "Tractor Loan",
      },
      {
        label: "Two-wheeler Loan",
        value: "Two-wheeler Loan",
      },
      {
        label: "Used Car Loan",
        value: "Used Car Loan",
      },
      {
        label: "Loan to Professional",
        value: "Loan to Professional",
      },
      {
        label: "Loan Against Shares / Securities",
        value: "Loan Against Shares / Securities",
      },
      {
        label: "Loan on Credit Card",
        value: "Loan on Credit Card",
      },
    ],
  },
  { id: "approve", type: "number" },
  { id: "conditional_approval_approval_limit", type: "number" },
  { id: "conditional_approval_value1", type: "number" },
  { id: "conditional_approval_value2", type: "number" },
  { id: "refer", type: "number" },
  { id: "reject", type: "number" },
  { id: "default_date", type: "number" },
  { id: "write_off_date", type: "number" },
  { id: "specific_exclusion1_value1", type: "number" },
  { id: "specific_exclusion2_value2", type: "number" },
  { id: "specific_exclusion_2_value1a", type: "number" },
  { id: "specific_exclusion_2_value2a", type: "number" },
  { id: "specific_exclusion_2_value3a", type: "number" },
  {
    id: "specific_exclusion_2_applicability3a",
    type: "select",
    options: [
      {
        label: "Full",
        value: "full",
      },
      {
        label: "Selective",
        value: "selective",
      },
    ],
  },
  { id: "selective_category_1_category", type: "select" },
  { id: "selective_category_1_value", type: "number" },
  { id: "selective_category_2_category", type: "select" },
  { id: "selective_category_2_value", type: "number" },
  { id: "selective_category_3_category", type: "select" },
  { id: "selective_category_3_value", type: "number" },
  { id: "selective_category_4_category", type: "select" },
  { id: "selective_category_4_value", type: "number" },
  { id: "selective_category_5_category", type: "select" },
  { id: "selective_category_5_value", type: "number" },
  { id: "selective_category_6_category", type: "select" },
  { id: "selective_category_6_value", type: "number" },
  { id: "selective_category_7_category", type: "select" },
  { id: "selective_category_7_value", type: "number" },
];

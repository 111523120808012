import React, { useEffect, useState } from "react";
import { useTable, useGlobalFilter } from "react-table";
import BTable from "react-bootstrap/Table";
import DropdownFilter from "./DropdownFilter";
import { useLocation } from "react-router-dom";

const Table = ({
  columns,
  data,
  defaultColumn,
  updateTableData,
  skipPageReset,
  filterOptions,
  tableHeadingStyles,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateTableData,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter
  );
  const location = useLocation();
  const { globalFilter } = state;

  const [headerHeight, setHeaderHeight] = useState();
  const [filterHeight, setFilterHeight] = useState();

  useEffect(() => {
    setGlobalFilter("");
    setHeaderHeight(document.querySelector("#header").offsetHeight);
    setFilterHeight(document.querySelector("#filter").offsetHeight);
    document.getElementById("filter").getElementsByTagName("select")[0].value =
      "";
  }, [location]);

  return (
    <div className="px-4">
      <div id={"filter"} className="sticky-top">
        <DropdownFilter
          className={"p-2"}
          showSVG={true}
          style={{ fontSize: "15px", width: "auto" }}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          options={filterOptions}
        />
      </div>
      <div
        id={"table"}
        className="overflow-scroll"
        style={{
          height: `calc(100vh - ${headerHeight}px - ${filterHeight}px - 28px - 90px)`,
          fontSize: "10px",
        }}
      >
        <BTable {...getTableProps()} className={""}>
          <thead className="" style={{}}>
            {headerGroups.map((headerGroup) => (
              <tr className="" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className="py-2 px-4"
                      style={{
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                        fontStyle: "normal",
                        lineHeight: "24px",
                        border: "1px solid black",
                        verticalAlign: "middle",
                        ...tableHeadingStyles(column),
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            style={{ fontSize: "12.5px", lineHeight: "16px" }}
          >
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  id={row.original.row_id}
                  {...row.getRowProps()}
                  style={{
                    backgroundColor: i % 2 === 0 ? "#ffffff" : "#f5f9ff",
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className=""
                        style={{
                          padding: "10px 10px 16px 16px",
                          color: "#122248",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
      </div>
    </div>
  );
};

export default Table;

export const RepeatClientSelectableTC = [
  "specific_exclusion_2_applicability3a",
  "selective_category_1_category",
  "selective_category_2_category",
  "selective_category_3_category",
  "selective_category_4_category",
  "selective_category_5_category",
  "selective_category_6_category",
  "selective_category_7_category",
];

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { filter } from "../images";

const DropdownFilter = ({
  id,
  name,
  options,
  className,
  style,
  globalFilter,
  setGlobalFilter,
  showSVG,
}) => {
  const [value, setValue] = useState(globalFilter);
  const location = useLocation();

  useEffect(() => {
    setValue();
  }, [location]);

  let onChange = (e) => {
    setValue(e.target.value || undefined);
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div className="px-0 d-flex align-items-center gap-3">
      {showSVG && (
        <img
          src={filter}
          style={{ height: "38px", fill: "#64748b" }}
          alt={"filter"}
        ></img>
      )}
      <select
        style={style}
        className={className}
        id={id}
        name={name}
        defaultValue={""}
        value={value}
        onChange={onChange}
      >
        <option value={""} disabled={true}>
          {"Select Filters"}
        </option>
        {options &&
          options.map((option, key) => (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};
export default DropdownFilter;
